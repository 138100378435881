import React, { useState, useEffect } from 'react';
import { StyleSheet, Text, View, TouchableOpacity, Image, Modal } from 'react-native';

const Tutorial = ({ tutorialVisible, setTutorialVisible }) => {
  const [currentPage, setCurrentPage] = useState(0);

  const pages = [
    {
      title: 'Rules',
      content: (
        <>
          <Text style={styles.desc}>Pieces move as they do in chess, but you can only take matching pieces:</Text>
          <Text style={styles.desc}>Pawns take pawns, knights take knights, and so on.</Text>
          <Text style={styles.desc}>A capture will level up your piece to the next piece.</Text>
          <Text style={styles.desc}>Every move, a new pawn will appear. Try not to run out of moves, and reach the golden king!</Text>
          <Text style={styles.desc}>If a blue piece takes, or is taken, by a black piece, then it will upgrade to the next blue piece.</Text>
          <Text style={styles.desc}>In any capture, the higher color takes priority!</Text>
        </>
      ),
    },
    {
        title: 'Move Pt. 1',
        content: (
          <>
            <View style={styles.pieceRule}>
              <Image style={[styles.pieceImage, { width: 40, height: 32.79 }]} source={require('../assets/pawnT.png')} />
              <Text style={styles.moveDesc}>Pawn: Move one space forward, capture one space diagonally forward.</Text>
            </View>
            <View style={styles.pieceRule}>
              <Image style={[styles.pieceImage, { width: 40, height: 36.8 }]} source={require('../assets/knightT.png')} />
              <Text style={styles.moveDesc}>Knight: Moves and captures two squares vertically and one square horizontally, or vice versa. It can jump over pieces!</Text>
            </View>
            <View style={styles.pieceRule}>
              <Image style={[styles.pieceImage, { width: 40, height: 36.8 }]} source={require('../assets/bishopT.png')} />
              <Text style={styles.moveDesc}>Bishop: Move and captures diagonally, any distance.</Text>
            </View>
            <View style={styles.pieceRule}>
              <Image style={[styles.pieceImage, { width: 40, height: 33.89 }]} source={require('../assets/rookT.png')} />
              <Text style={styles.moveDesc}>Rook: Moves and captures vertically or horizontally, any distance.</Text>
            </View>
            <View style={styles.pieceRule}>
              <Image style={[styles.pieceImage, { width: 40, height: 38.62 }]} source={require('../assets/queenT.png')} />
              <Text style={styles.moveDesc}>Queen: Moves and captures in any direction, any distance.</Text>
            </View>
          </>
        ),
      },
      {
        title: 'Move Pt. 2',
        content: (
          <>
            <View style={styles.pieceRule}>
              <Image style={[styles.pieceImage, { width: 40, height: 41.9 }]} source={require('../assets/kingT.png')} />
              <Text style={styles.moveDesc}>King: Moves and captures in any direction, one square.</Text>
            </View>
            <View style={styles.pieceRule}>
              <Image style={[styles.pieceImage, { width: 40, height: 35.28 }]} source={require('../assets/astronautT.png')} />
              <Text style={styles.moveDesc}>Astropawn: Captures any pawn and moves to any open space on the board.</Text>
            </View>
            <View style={styles.pieceRule}>
              <Image style={[styles.pieceImage, { width: 40, height: 32.9 }]} source={require('../assets/downT.png')} />
              <Text style={styles.moveDesc}>Rebel pawns: Move and capture like pawns, except in the facing direction.</Text>
            </View>
            <View style={styles.pieceRule}>
              <Image style={[styles.pieceImage, { width: 40, height: 42 }]} source={require('../assets/redbombT.png')} />
              <Text style={styles.moveDesc}>Pawmb: Move and capture like pawns but blow up the four adjacent squares if not moved or taken. Blown up colored pieces will turn into pawns from one color below.</Text>
            </View>
          </>
        ),
      },
    {
      title: 'Progression & Spawn Rates',
      content: (
        <>
            <Image style={[styles.pieceImage, { width: 165.6, height: 34.4 }]} source={require('../assets/pieceProgression.png')} />
            <Image style={[styles.pieceImage, { width: 218.95, height: 26.32 }]} source={require('../assets/moveProgression.png')} />
          <View style={styles.spawnRate}>
            <Text style={styles.desc}>Rate after first rook:</Text>
            <Image style={[styles.pieceImage, { width: 53.13, height: 51 }]} source={require('../assets/astropawnmoves.png')} />
          </View>
          <View style={styles.spawnRate}>
            <Text style={styles.desc}>Rate after first queen:</Text>
            <Image style={[styles.pieceImage, { width: 154, height: 51 }]} source={require('../assets/rebelmoves.png')} />
          </View>
          <View style={styles.spawnRate}>
            <Text style={styles.desc}>Rate after first king:</Text>
            <Image style={[styles.pieceImage, { width: 191.59, height: 52 }]} source={require('../assets/redpawmbrates.png')} />
          </View>
          <Text style={styles.desc2}>(Pawmbs do not spawn in a row.)</Text>
        </>
      ),
    },
  ];

  const handleNextPage = () => {
    if (currentPage < pages.length - 1) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  return (
    <Modal transparent={true} visible={tutorialVisible} animationType="fade" style={styles.Modal}>
      <TouchableOpacity
        style={styles.container}
        activeOpacity={1}
        onPress={() => {
          console.log('Modal has been clicked.');
          setTutorialVisible(!tutorialVisible);
        }}
      >
        <View style={styles.popupBox}>
            <View style={styles.textBox}>
                <Text style={styles.header}>{pages[currentPage].title}</Text>
                {pages[currentPage].content}
            </View>
            <View style={styles.pagination}>
                {currentPage == 0 && (
                    <TouchableOpacity style={styles.paginationButton}>
                    <Image style={{ width: 20, height: 20, opacity: 0.3, resizeMode: 'contain' }} source={require('../assets/arrowleft.png')} />
                    </TouchableOpacity>
                )}
                {currentPage != 0 && (
                    <TouchableOpacity style={styles.paginationButton} onPress={handlePreviousPage}>
                    <Image style={{ width: 20, height: 20, resizeMode: 'contain' }} source={require('../assets/arrowleft.png')} />
                    </TouchableOpacity>
                )}
                <View style={styles.pageNumberContainer}>
                    <Text style={styles.pageNumberText}>{`${currentPage + 1}/${pages.length}`}</Text>
                </View>
                {currentPage == 4 && (
                    <TouchableOpacity style={styles.paginationButton}>
                    <Image style={{ width: 20, height: 20, opacity: 0.3, resizeMode: 'contain' }} source={require('../assets/arrowright.png')} />
                    </TouchableOpacity>
                )}
                {currentPage != 4 && (
                    <TouchableOpacity style={styles.paginationButton} onPress={handleNextPage}>
                    <Image style={{ width: 20, height: 20, resizeMode: 'contain' }} source={require('../assets/arrowright.png')} />
                    </TouchableOpacity>
                )}
            </View>
        </View>
      </TouchableOpacity>
    </Modal>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    shadowOpacity: 0.25,

    shadowColor: '#3A3733',
    shadowOffset: {
      height: 4,
    },
    shadowRadius: 4,
    elevation: 5,
  },
    pageNumberContainer: {
        width: 30,
        height: 'auto',
        justifyContent: 'center', // Align items vertically
        alignItems: 'center', // Align items horizontally
    },
  pageNumberText: {
    fontSize: 16,
    fontWeight: '600',
    color: '#756F66',
    alignSelf: 'center',
  },
  popupBox: {
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    height: 500,
    width: 360,
    flexDirection: 'column',
    backgroundColor: '#EBE4DB',
    borderColor: '#B9ADA1',
    borderWidth: 2,
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 30,
    paddingBottom: 0,
    borderRadius: 40,

    overflow: 'hidden',
  },
  textBox: {
    flexDirection: 'column',
    gap: 16,
    marginTop: 10,
  },
  header: {
    marginTop: 14,
    fontSize: 20,
    fontWeight: 'bold',
    color: '#756F66',
  },
  desc: {
    fontSize: 16,
    fontWeight: '300',
    color: '#756F66',
    width: 292,
    textAlign: 'left',
  },
  desc2: {
    fontSize: 12,
    marginTop: -6,
    fontWeight: '300',
    color: '#756F66',
    width: 292,
    textAlign: 'left',
  },
  moveDesc: {
    fontSize: 16,
    fontWeight: '300',
    color: '#756F66',
    width: 250,
    textAlign: 'left',
    display: 'flex',
  },
  pieceImage: {
    resizeMode: 'contain',
  },
  percentages: {
    resizeMode: 'contain',
  },
  spawnRate: {
    flexDirection: 'column',
    gap: 8,
  },
  pieceRule: {
    flexDirection: 'row',
  },
  pagination: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    marginBottom: 10,
    gap: 10,
  },
  paginationButton: {
    backgroundColor: 'transparent',
    padding: 6,
  },
});

export default Tutorial;
