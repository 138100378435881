import React, { useState } from 'react';
import { View, TouchableOpacity, StyleSheet } from 'react-native';

const Checkbox = ({onChange}) => {
  const [isChecked, setIsChecked] = useState(false);

  const toggleCheckbox = () => {
    setIsChecked(!isChecked);
    if (onChange) {
        onChange(!isChecked); // Pass the new checkbox state to the onChange callback
    }
  };

  return (
    <TouchableOpacity style={styles.checkboxContainer} onPress={toggleCheckbox}>
      <View style={[styles.checkbox, isChecked ? styles.checked : styles.unchecked]} />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  checkboxContainer: {
    padding: 12,
  },
  checkbox: {
    width: 20,
    height: 20,
    borderWidth: 1.5,
    borderRadius: 5,
  },
  checked: {
    backgroundColor: '#756F66',
    borderColor: '#756F66',
  },
  unchecked: {
    backgroundColor: '#EBE4DB',
    borderColor: '#756F66',
  },
});

export default Checkbox;