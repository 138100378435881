import React, { useState, useEffect } from 'react';
import { StyleSheet, Text, View, TouchableOpacity, Image, Modal } from 'react-native';
import Checkbox from './Checkbox';
import { MMKV } from 'react-native-mmkv'
export const storage = new MMKV();

const Popup = ({wave, modalVisible, setModalVisible, setPref, score, setPrefPuzzle}) => {
    if (modalVisible) {
        console.log("THE CURRENT WAVE IS " + wave);
    }
    if (wave == 10) {wave = 10;};
    if (wave == 7) {wave = 7;};
    const handleCheckboxChange = (checked) => {
        if (checked) {
          setPref(true);
          storage.set('pref', true);
        } else {
          setPref(false);
        }
    };
    const handlePuzzleBoxChange = (checked) => {
        if (checked) {
          setPrefPuzzle(true);
          storage.set('prefPuzzle', true);
        } else {
          setPrefPuzzle(false);
        }
    };
    const getPieceDimensions = () => {
        switch (wave) {
            case 0:
            return { width: 33.2, height: 55.34 };
            case 1:
            return { width: 33.2, height: 59.34 };
            case 2:
            return { width: 150, height: 66 };
            case 3:
            return { width: 62, height: 73 };
            case 4:
            return { width: 33.2, height: 55.34 };
            case 5:
            return { width: 54, height: 91 };
            case 6:
            return { width: 33.2, height: 55.34 };
            case 7:
            return { width: 33.2, height: 55.34 };
            case 10:
            return { width: 33.2, height: 55.34 };
            default:
            return { width: 33.2, height: 55.34 };
        };
    };
    const getPercentagesDimensions = () => {
        switch (wave) {
            case 0:
            return { width: 53.13, height: 51 };
            case 1:
            return { width: 53.13, height: 51 };
            case 2:
            return { width: 160.73, height: 51 };
            case 3 :
            return { width: 182.54, height: 52 };
            case 4 :
            return { width: 182.54, height: 52 };
            case 5 :
            return { width: 182.54, height: 52 };
            case 6:
            return { width: 53.13, height: 51 };
            case 7:
            return { width: 53.13, height: 51 };
            default:
            return null;
        };
    };
    const renderPiece = () => {
        let pieceImageSource;
        const pieceDimensions = getPieceDimensions();
        switch (wave) {
            case 0:
            pieceImageSource = require('../assets/pawn2.png');
            break;
            case 1:
            pieceImageSource = require('../assets/astronaut.png');
            break;
            case 2:
            pieceImageSource = require('../assets/rebels.png');
            break;
            case 3:
            pieceImageSource = require('../assets/redbomb2.png');
            break;
            case 4:
            pieceImageSource = require('../assets/bluepawn.png');
            break;
            case 5:
            pieceImageSource = require('../assets/goldenking.png');
            break;
            case 6:
            pieceImageSource = require('../assets/pawn2.png');
            break;
            case 7:
            pieceImageSource = require('../assets/pawn2.png');
            break;
            case 10:
            pieceImageSource = require('../assets/pawn2.png');
            break;
            default:
            pieceImageSource = require('../assets/pawn2.png');
            break;
        }
        return (
            <Image
            source={pieceImageSource}
            style={[styles.pieceImage, pieceDimensions]}
            />
        )
    }
    const renderPercentages = () => {
        let percentagesImageSource;
        const percentagesDimensions = getPercentagesDimensions();
        switch (wave) {
            case 0:
            percentagesImageSource = null;
            break;
            case 1:
            percentagesImageSource = require('../assets/astropawnmoves.png');
            break;
            case 2:
            percentagesImageSource = require('../assets/rebelmoves.png');
            break;
            case 3:
            percentagesImageSource = require('../assets/redpawmbrates.png');
            break;
            case 4:
            percentagesImageSource = require('../assets/redpawmbrates.png');
            break;
            default:
            percentagesImageSource = null;
            break;
        }
        if (wave == 0) {
            return (
                <View style={styles.checkbox}>
                    <Checkbox onChange={handleCheckboxChange} />
                    <Text style={styles.checkboxText}>Got it, don’t tell me again</Text>
                </View>
            )
        } else if (wave == 7) {
            return (
                <View style={styles.checkbox}>
                    <Checkbox onChange={handlePuzzleBoxChange} />
                    <Text style={styles.checkboxText}>Got it, don’t tell me again</Text>
                </View>
            )
        } else if (wave == 4 || wave == 5 || wave == 6 || wave == 10) {
            return;
        } else {
            return (
                <Image
                source={percentagesImageSource}
                style={[styles.percentagesImage, percentagesDimensions]}
                />
            )            
        }
    }
    const getHeader = () => {
        switch (wave) {
            case 0:
                return "Welcome to Take!";
            case 1:
              return "The astropawn has landed!";
            case 2:
              return "The pawns are rebelling!";
            case 3:
              return "Watch out for pawmbs!";
            case 4:
                return "Here comes the blue wave!";
            case 5:
                return "You won the golden king!";
            case 6:
                return "Stalemate!";
            case 7:
                return "Welcome to Daily Puzzle!";
            case 10:
                console.log("checkHeader!");
                return "You're in for an update!";
            default:
                return "Welcome to Take!";
        };
    }
    const getDesc = () => {
    switch (wave) {
        case 0:
        return `Pieces move as they do in chess, but you can only take matching pieces:

Pawns take pawns, knights take knights, and so on.

A capture will level up your piece to the next piece.

Every move, a new pawn will appear. Try not to run out of moves, and reach the golden king!`;
        case 1:
        return "Astropawns can capture any pawn and move to any open space on the board. Use them wisely!";
        case 2:
        return "Pawns will now face, move, and take in four different directions. And it's not just a phase!";
        case 3:
        return "If not moved or captured after one move, the pawmb will blow up pieces on the four adjacent spaces. Kaboom!";
        case 4:
        return `If a blue piece takes, or is taken, by a black piece, then it will upgrade to the next blue piece.
    
In any capture, the higher color takes priority!

Blown up colored pieces will turn into pawns from one color below.`;
        case 5:
        return `You are officially a Take grandmaster!

Keep playing to maximize your score. How many golden kings can you get?`;
        case 6:
return `You don’t have any more legal moves remaining.

Your final score is ${score}!`;
        case 7:
            return `Every day, compete against your friends to see who can complete the puzzle in the least moves!
            
You have 25 moves to get to the golden king. Good luck!`;
        case 10:
        console.log("checkDesc!");
            return `Please update to the latest version to access new puzzles and the latest features!`;
        default:
return `Pieces move as they do in chess, but you can only take matching pieces:
        
Pawns take pawns, knights take knights, and so on.
        
A capture will level up your piece to the next piece.
        
Every move, a new pawn will appear. Try not to run out of moves, and reach the golden king!`;
    }
    };



return (
    <Modal 
        transparent={true}
        visible={modalVisible}
        animationType="fade"
    >
    <TouchableOpacity 
        style={styles.container}
        activeOpacity={1}
        onPress={() => {
            console.log('Modal has been clicked.');
            setModalVisible(!modalVisible);
        }}
    >
    <View style={styles.popupBox}>
            {renderPiece()}
            <View style={styles.textBox}>
                <Image style={styles.piece}></Image>
                <Text style={styles.header}>{getHeader()}</Text>
                <Text style={styles.desc}>{getDesc()}</Text>
                <Image style={styles.percentages}></Image>
            </View>
            {renderPercentages()}
        </View>     
    </TouchableOpacity>   
    </Modal>
);
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    popupBox: {
        display: 'flex',
        // flex: 0,
        flexDirection: 'column',
        backgroundColor: '#EBE4DB',
        borderColor: '#B9ADA1',
        borderWidth: 2,
        alignItems: 'center',
        justifyContent: 'center',
        paddingHorizontal: 30,
        paddingTop: 20,
        paddingBottom: 20,
        borderRadius: 40,
        gap: 16,
        shadowOpacity: 0.25,

        shadowColor: '#3A3733',
        shadowOffset: {
            height: 4,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
    },
    textBox : {
        flexDirection: 'column',
        gap: 4,
    },
    header : {
        fontSize: 20,
        fontWeight: 'bold',
        color: '#756F66',
        textAlign: 'left',
    },
    desc : {
        fontSize: 16,
        fontWeight: '300',
        color: '#756F66',
        width: 292,
        // textAlign: 'left',
    },
    pieceImage: {
        // borderWidth: 1,
        // borderColor: 'black',
        resizeMode: 'contain',
    },
    percentagesImage: {
        // borderWidth: 1,
        // borderColor: 'black',
        resizeMode: 'contain',
    },
    checkbox: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    checkboxText: {
        fontSize: 16,
        fontWeight: '300',
        color: '#756F66',
    }
});

export default Popup;