import React from 'react';
import { View, StyleSheet, Image, TouchableOpacity } from 'react-native';

const Square = ({ id, hasPiece, pieceType, onPress, selected, tintColor }) => {
  const getPieceDimensions = () => {
    switch (pieceType) {
      case 'pawn':
        return { width: 33.2, height: 55.34 };
      case 'astronaut':
        return { width: 33.2, height: 59.34 };
      case 'down' :
        return { width: 33.2, height: 69 };
      case 'left' :
        return { width: 69, height: 51 };
      case 'right' :
        return { width: 69, height: 51 };
      case 'bomb' :
        // return { width: 36.18, height: 62.34 };
        return { width: 62, height: 73};
      case 'knight':
        return { width: 40.11, height: 62.1 };
      case 'bishop':
        return { width: 33.2, height: 62.1 };
      case 'rook':
        return { width: 33.2, height: 57.19 };
      case 'queen':
        return { width: 33.21, height: 65.18 };
      case 'king':
        return { width: 33.2, height: 70.71 };
      default:
        return { width: 0, height: 0 };
    }
  };

  const renderPiece = () => {
    if (hasPiece) {
      let pieceImageSource;
      if (tintColor == '#EECD23') {
        switch (pieceType) {
          case 'pawn':
            pieceImageSource = require('../assets/pawngold.png');
            break;
          case 'knight':
            pieceImageSource = require('../assets/knightgold.png');
            break;
          case 'bishop':
            pieceImageSource = require('../assets/bishopgold.png');
            break;
          case 'rook':
            pieceImageSource = require('../assets/rookgold.png');
            break;
          case 'queen':
            pieceImageSource = require('../assets/queengold.png');
            break;
          case 'king':
            pieceImageSource = require('../assets/kinggold.png');
            break;
          default:
            pieceImageSource = null;
        }
      } else {
        switch (pieceType) {
          case 'pawn':
            pieceImageSource = require('../assets/pawn2.png');
            break;
          case 'astronaut':
            pieceImageSource = require('../assets/astronaut.png');
            break;
          case 'down':
            pieceImageSource = require('../assets/down2.png');
            break;
          case 'left':
            pieceImageSource = require('../assets/left.png');
            break;
          case 'right':
            pieceImageSource = require('../assets/right.png');
            break;
          case 'bomb':
            pieceImageSource = require('../assets/redbomb2.png');
            break;
          case 'knight':
            pieceImageSource = require('../assets/knight2.png');
            break;
          case 'bishop':
            pieceImageSource = require('../assets/bishop2.png');
            break;
          case 'rook':
            pieceImageSource = require('../assets/rook2.png');
            break;
          case 'queen':
            pieceImageSource = require('../assets/queen2.png');
            break;
          case 'king':
            pieceImageSource = require('../assets/king2.png');
            break;
          default:
            pieceImageSource = null;
        }
      }

      const pieceDimensions = getPieceDimensions();
      if (tintColor === "#EECD23") {
        return (
          <Image
          source={pieceImageSource}
          style={[
            styles.pieceImage,
            pieceDimensions,
            {
              shadowColor: "#FFFFFF",
              shadowOpacity: 1,
              shadowRadius: 8,
              shadowOffset: { width: 0, height: 0 },
            },
          ]}
        />
        );
      } else if (pieceType === "astronaut" || pieceType === "bomb") {
        return (
          <Image
            source={pieceImageSource}
            style={[styles.pieceImage, pieceDimensions]}
          />
        );
      } else {
        return (
          <Image
            source={pieceImageSource}
            style={[styles.pieceImage, pieceDimensions, { tintColor: tintColor }]}
          />
        );
      }
    }
    return null;
  };

  const row = Math.floor(id / 4);
const column = id % 4;
const isEvenRow = row % 2 === 0;
const isEvenColumn = column % 2 === 0;

const backgroundColor = isEvenRow
  ? isEvenColumn
    ? '#EBE4DB'
    : '#D1CAC0'
  : isEvenColumn
  ? '#D1CAC0'
  : '#EBE4DB';


  return (
    <TouchableOpacity
      style={[
        styles.square,
        { backgroundColor: selected ? '#FFFFFF' : backgroundColor },
      ]}
      onPress={onPress}
      activeOpacity={1}
    >
      {renderPiece()}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  square: {
    // borderWidth: 1,
    // borderColor: 'black',
    width: 80,
    height: 80,
    margin: 0,
    borderRadius: 0,
    backgroundColor: '#EBE4DB',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },

  pieceImage: {
    // borderWidth: 1,
    // borderColor: 'black',
    resizeMode: 'contain',
    marginBottom: 5,
    overflow: 'visible',
  },
});

export default Square;