import { StatusBar } from 'expo-status-bar';
import * as NavigationBar from 'expo-navigation-bar';
import { StyleSheet, Text, TouchableOpacity, SafeAreaView, View, Platform } from 'react-native';
import Gameboard from './components/Gameboard'
import { Audio } from 'expo-av';
import * as React from 'react';


export default function App() {
  if (Platform.OS === 'android') {
      NavigationBar.setBackgroundColorAsync("white");
      NavigationBar.setVisibilityAsync("hidden");
      NavigationBar.setBehaviorAsync("overlay-swipe");
  }

  const [sound, setSound] = React.useState();

  async function playMove() {
    // console.log('Loading Sound');
    const { sound } = await Audio.Sound.createAsync( require('./assets/move.mp3')
    );
    setSound(sound);

    // console.log('Playing Sound');
    await sound.playAsync();
  }

  async function playCapture() {
    // console.log('Loading Sound');
    const { sound } = await Audio.Sound.createAsync( require('./assets/capture.mp3')
    );
    setSound(sound);

    // console.log('Playing Sound');
    await sound.playAsync();
  }

  async function playBoom() {
    // console.log('Loading Sound');
    const { sound } = await Audio.Sound.createAsync( require('./assets/boom.mp3')
    );
    setSound(sound);

    // console.log('Playing Sound');
    await sound.playAsync();
  }

  async function playWarp() {
    // console.log('Loading Sound');
    const { sound } = await Audio.Sound.createAsync( require('./assets/warp.mp3')
    );
    setSound(sound);

    // console.log('Playing Sound');
    await sound.playAsync();
  }

  React.useEffect(() => {
    return sound
      ? () => {
          // console.log('Unloading Sound');
          sound.unloadAsync();
        }
      : undefined;
  }, [sound]);

  return (
    <Gameboard playMove={playMove} playCapture={playCapture} playBoom={playBoom} playWarp={playWarp} />
  );
  
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
  board: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: 400,
    height: 400,
    backgroundColor: '#B9ADA1',
    paddingTop: 8,
    paddingLeft: 8,
    borderRadius: 22,
  }
});
