import React, { useState, useEffect } from 'react'
import { StyleSheet, Text, View, TouchableOpacity, Linking, Image, Modal } from 'react-native'
import Square from './Square'
import Popup from './Popup'
import { MMKV } from 'react-native-mmkv'
import Tutorial from './Tutorial'
import ShareComponent from './Share'
export const storage = new MMKV()

const Gameboard = ({ playMove, playCapture, playWarp, playBoom }) => {

    const [isClassic, setClassic] = useState(true);
    const [waveClassic, setWaveClassic] = useState(0);
    const [wavePuzzle, setWavePuzzle] = useState(0);
    const wave = isClassic ? waveClassic : wavePuzzle;
    const setWave = isClassic ? setWaveClassic : setWavePuzzle;
    let waveChange = false;
    const [movesLeftPuzzle, setMovesLeftPuzzle] = useState(25);
    const [previousDay, setPreviousDay] = useState(30);
    let currentDate = new Date();
    currentDate.setDate(currentDate.getDate());
    const [outcome, setOutcome] = useState(null);
    const startDate = new Date(2023, 6, 6); // July 6, 2023 (month is zero-based)
    let formattedDate = currentDate.toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric'
    });
    let dayNumber = Math.floor((currentDate - startDate) / (1000 * 60 * 60 * 24));
    
    useEffect(() => {
        let currentDay = currentDate.getDate();
        dayNumber = Math.floor((currentDate - startDate) / (1000 * 60 * 60 * 24));
        formattedDate = currentDate.toLocaleDateString('en-US', {
            month: 'long',
            day: 'numeric',
            year: 'numeric'
        });
        let savedPreviousDay = storage.getNumber('previousDay');
        if (savedPreviousDay) {
            setPreviousDay(savedPreviousDay);
        }
        if (currentDay !== savedPreviousDay) {
            setOutcome(-1);
            storage.set('outcome', -1);
        }

        if (!isClassic) {
            setWave(7);
            storage.set(getWaveKey(), 7);
            if (currentDay !== savedPreviousDay) {
                setMovesLeftPuzzle(25);
                storage.set('movesLeftPuzzle', 25);
                setWave(0);
                storage.set(getWaveKey(), 0);
    
                generatePuzzle();
    
                setPreviousDay(currentDay);
                storage.set('previousDay', currentDay);
            }
        }
    }, [currentDate, isClassic]);

    const [boardStateClassic, setBoardStateClassic] = useState(Array(16).fill({ pieceType: null, hasPiece: false, tintColor: null, isPawn: null }));
    const [boardStatePuzzle, setBoardStatePuzzle] = useState(Array(16).fill({ pieceType: null, hasPiece: false, tintColor: null, isPawn: null }));
    const [selectedPieceIndex, setSelectedPieceIndex] = useState(null);
    const [score, setScore] = useState(0);
    const [highScore, setHighScore] = useState(20);
    const [bombCoordsClassic, setBombCoordsClassic] = useState(null);
    const [bombSpawnClassic, setBombSpawnClassic] = useState(false);
    const [bombCoordsPuzzle, setBombCoordsPuzzle] = useState(null);
    const [bombSpawnPuzzle, setBombSpawnPuzzle] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [pref, setPref] = useState(false);
    const [prefPuzzle, setPrefPuzzle] = useState(false);
    const [tutorialVisible, setTutorialVisible] = useState(false);
    const [shareVisible, setShareVisible] = useState(false);
    const [isSoundOn, setIsSoundOn] = useState(true);

    const generateNewArray = () => {
        let n = { pieceType: null, hasPiece: false, tintColor: null, isPawn: null };
        let b = { pieceType: 'bomb', hasPiece: true, tintColor: "#756F66", isPawn: true };
        let d = { pieceType: 'down', hasPiece: true, tintColor: "#756F66", isPawn: true };
        let r = { pieceType: 'right', hasPiece: true, tintColor: "#756F66", isPawn: true };
        let l = { pieceType: 'left', hasPiece: true, tintColor: "#756F66", isPawn: true };
        let a = { pieceType: 'astronaut', hasPiece: true, tintColor: "#756F66", isPawn: true };
        let np = { pieceType: 'pawn', hasPiece: true, tintColor: "#756F66", isPawn: true };
        let nn = { pieceType: 'knight', hasPiece: true, tintColor: "#756F66", isPawn: false };
        let nb = { pieceType: 'bishop', hasPiece: true, tintColor: "#756F66", isPawn: false };
        let nr = { pieceType: 'rook', hasPiece: true, tintColor: "#756F66", isPawn: false };
        let nq = { pieceType: 'queen', hasPiece: true, tintColor: "#756F66", isPawn: false };
        let nk = { pieceType: 'king', hasPiece: true, tintColor: "#756F66", isPawn: false };
        let ap = { pieceType: 'pawn', hasPiece: true, tintColor: "#76BDFF", isPawn: true };
        let an = { pieceType: 'knight', hasPiece: true, tintColor: "#76BDFF", isPawn: false };
        let ab = { pieceType: 'bishop', hasPiece: true, tintColor: "#76BDFF", isPawn: false };
        let ar = { pieceType: 'rook', hasPiece: true, tintColor: "#76BDFF", isPawn: false };
        let aq = { pieceType: 'queen', hasPiece: true, tintColor: "#76BDFF", isPawn: false };
        let ak = { pieceType: 'king', hasPiece: true, tintColor: "#76BDFF", isPawn: false };
        let lp = { pieceType: 'pawn', hasPiece: true, tintColor: "#6CC057", isPawn: true };
        let ln = { pieceType: 'knight', hasPiece: true, tintColor: "#6CC057", isPawn: false };
        let lb = { pieceType: 'bishop', hasPiece: true, tintColor: "#6CC057", isPawn: false };
        let lr = { pieceType: 'rook', hasPiece: true, tintColor: "#6CC057", isPawn: false };
        let lq = { pieceType: 'queen', hasPiece: true, tintColor: "#6CC057", isPawn: false };
        let lk = { pieceType: 'king', hasPiece: true, tintColor: "#6CC057", isPawn: false };
        let op = { pieceType: 'pawn', hasPiece: true, tintColor: "#F89D49", isPawn: true };
        let on = { pieceType: 'knight', hasPiece: true, tintColor: "#F89D49", isPawn: false };
        let ob = { pieceType: 'bishop', hasPiece: true, tintColor: "#F89D49", isPawn: false };
        let or = { pieceType: 'rook', hasPiece: true, tintColor: "#F89D49", isPawn: false };
        let oq = { pieceType: 'queen', hasPiece: true, tintColor: "#F89D49", isPawn: false };
        let ok = { pieceType: 'king', hasPiece: true, tintColor: "#F89D49", isPawn: false };
        let rp = { pieceType: 'pawn', hasPiece: true, tintColor: "#FF7676", isPawn: true };
        let rn = { pieceType: 'knight', hasPiece: true, tintColor: "#FF7676", isPawn: false };
        let rb = { pieceType: 'bishop', hasPiece: true, tintColor: "#FF7676", isPawn: false };
        let rr = { pieceType: 'rook', hasPiece: true, tintColor: "#FF7676", isPawn: false };
        let rq = { pieceType: 'queen', hasPiece: true, tintColor: "#FF7676", isPawn: false };
        let rk = { pieceType: 'king', hasPiece: true, tintColor: "#FF7676", isPawn: false };
        let pp = { pieceType: 'pawn', hasPiece: true, tintColor: "#C46FF9", isPawn: true };
        let pn = { pieceType: 'knight', hasPiece: true, tintColor: "#C46FF9", isPawn: false };
        let pb = { pieceType: 'bishop', hasPiece: true, tintColor: "#C46FF9", isPawn: false };
        let pr = { pieceType: 'rook', hasPiece: true, tintColor: "#C46FF9", isPawn: false };
        let pq = { pieceType: 'queen', hasPiece: true, tintColor: "#C46FF9", isPawn: false };
        let pk = { pieceType: 'king', hasPiece: true, tintColor: "#C46FF9", isPawn: false };
        let bp = { pieceType: 'pawn', hasPiece: true, tintColor: "#CD7F32", isPawn: true };
        let bn = { pieceType: 'knight', hasPiece: true, tintColor: "#CD7F32", isPawn: false };
        let bb = { pieceType: 'bishop', hasPiece: true, tintColor: "#CD7F32", isPawn: false };
        let br = { pieceType: 'rook', hasPiece: true, tintColor: "#CD7F32", isPawn: false };
        let bq = { pieceType: 'queen', hasPiece: true, tintColor: "#CD7F32", isPawn: false };
        let bk = { pieceType: 'king', hasPiece: true, tintColor: "#CD7F32", isPawn: false };
        let sp = { pieceType: 'pawn', hasPiece: true, tintColor: "#A4A4A4", isPawn: true };
        let sn = { pieceType: 'knight', hasPiece: true, tintColor: "#A4A4A4", isPawn: false };
        let sb = { pieceType: 'bishop', hasPiece: true, tintColor: "#A4A4A4", isPawn: false };
        let sr = { pieceType: 'rook', hasPiece: true, tintColor: "#A4A4A4", isPawn: false };
        let sq = { pieceType: 'queen', hasPiece: true, tintColor: "#A4A4A4", isPawn: false };
        let sk = { pieceType: 'king', hasPiece: true, tintColor: "#A4A4A4", isPawn: false };
        let gp = { pieceType: 'pawn', hasPiece: true, tintColor: "#EECD23", isPawn: true };
        let gn = { pieceType: 'knight', hasPiece: true, tintColor: "#EECD23", isPawn: false };
        let gb = { pieceType: 'bishop', hasPiece: true, tintColor: "#EECD23", isPawn: false };
        let gr = { pieceType: 'rook', hasPiece: true, tintColor: "#EECD23", isPawn: false };
        let gq = { pieceType: 'queen', hasPiece: true, tintColor: "#EECD23", isPawn: false };

        const arrays = [
            [pp, op, op, gq, rb, np, bp, pp, ap, rb, rp, rp, np, lp, lb, lp],
            [gq, ap, ap, lp, pp, pb, np, pn, ap, bp, pp, op, lp, on, sp, pn],
            [bn, sp, sp, gr, rp, rb, pb, op, lp, an, lp, pr, pp, sp, lp, op],
            [bb, bn, ob, gr, np, n, bp, op, lp, np, pb, lr, pp, ap, ap, ap],
            [rb, lp, ob, gr, sp, n, sp, lp, op, sp, nb, pr, rp, rp, ap, lp],
            [op, np, pp, pp, op, sb, sp, sp, rb, pp, sp, op, bp, op, ap, pp],
            [on, nb, ap, gq, np, sp, np, bp, rp, ap, nr, n, gq, n, n, an],
            [pp, lp, ap, ap, pn, pp, rp, sp, rp, gq, ap, sr, bp, op, rn, rp],
            [rb, rp, rp, ob, ap, lp, on, rp, bp, ap, bp, bp, gq, ap, pp, ab],
            [op, n, pp, bb, lp, pp, gq, sp, pp, sn, sp, nb, ap, np, np, pp],
            [ap, pp, sn, gq, lp, sp, lp, op, lp, rn, rp, bp, ap, np, bp, bp],
            [rp, ap, ap, sp, sp, np, gq, pp, pp, an, bp, np, bp, rp, op, pp],
            [bp, op, ln, gr, op, lb, lp, bb, np, nn, sp, rr, rp, np, lp, np],
            [gr, sb, rb, ln, n, or, nn, pp, n, rn, nn, lp, pp, ap, bp, lp],
            [ap, pr, bp, rp, sp, ln, sp, np, rp, pp, n, gr, an, lp, rp, ap],
            [a, n, n, lr, n, n, n, n, n, n, ap, pp, rr, n, np, gq],
            [or, pn, np, gr, np, bb, pp, bp, op, np, rp, pp, np, lp, sp, pp],
            [on, bp, rr, lp, pp, an, ap, pp, sp, lb, gr, rp, bn, sp, rp, bp],
            [sp, rp, op, ln, ab, rn, np, lb, rp, sp, lb, gr, bp, rp, an, pr],
            [sp, a, a, a, a, gq, ap, a, a, lp, np, a, a, a, a, a],
            [rp, on, op, sb, sp, pp, rb, ap, rp, lr, on, gr, np, rp, an, op],
            [nb, bn, ab, gr, lb, ob, bb, ab, lb, nn, pn, sr, ab, nb, ab, lb],
            [on, op, lp, gq, lp, sn, rp, op, pp, np, rn, rp, sp, ap, np, on],
            [np, pb, np, pp, sp, ob, ap, pp, lr, sp, rp, gr, sp, lp, lp, bp],
            [on, rn, pn, an, pn, rn, rn, rn, bb, sn, ob, gr, on, an, bn, rr],
            [ap, sp, ap, ap, np, rp, ap, bp, gq, ap, bp, ap, bp, sp, lp, pb],
            [rn, nn, ob, gr, lp, pp, rp, rp, lp, rn, ap, sp, op, bp, bp, pp],
            [ap, nr, np, gq, op, bb, np, rp, ap, rp, sb, ap, lp, op, np, ap],
            [sp, ob, np, gq, pb, bp, sp, op, rp, bb, lp, pp, pp, np, sb, op],
            [np, op, rp, gq, np, rp, rp, rp, nn, rn, ap, ap, bp, sn, ap, lp],
            [ar, pr, ap, gr, sp, sp, rp, rp, bp, op, np, pp, bp, pp, rp, ap],
            [np, lp, lp, rp, rp, gq, bp, bp, rp, np, pp, np, op, bp, ar, rp],
            [sp, rp, lb, op, ap, n, on, op, sp, np, sn, np, pp, ap, gq, np],
            [op, an, lp, op, bn, gq, pn, rp, bp, on, rr, np, op, np, lp, sp],
            [nr, rb, bp, gr, np, sp, sp, bp, rp, rp, np, np, rp, lp, pp, np],
            [op, lb, lp, lb, np, rp, rp, ap, sp, gq, pp, np, np, np, bp, ab],
            [bp, ap, pp, gq, bp, lp, rb, rp, pn, an, ap, rp, sp, on, rp, np],
            [pp, lp, op, sp, pp, bb, bb, lp, rp, pn, ar, gr, pn, np, sp, np],
            [bp, np, bp, sp, np, pp, np, bp, bb, pp, pb, gr, bn, bp, pb, sr],
            [ap, ap, np, bp, op, gr, pb, ob, np, ab, nr, ap, ap, pp, ap, op],
            [ab, pp, lp, np, op, pp, gq, pp, pp, np, np, rp, sb, ap, sp, bp],
            [sb, bp, bp, np, on, bp, sp, bp, rp, gq, bp, br, pp, op, on, ap],
            [rp, pp, ap, ap, ap, pp, rp, np, op, lp, rp, pp, np, pp, bp, np],
            [sp, n, n, gq, np, n, pr, n, np, ln, rp, nn, np, sp, bp, rp],
            [op, pb, rp, pb, pp, lp, sn, pp, sp, gq, rp, op, rp, sp, sp, ab],
            [pp, np, sp, gq, rp, ob, op, op, ap, on, ap, rp, ap, bb, bp, lp],
            [rp, bp, bp, bn, bp, np, gq, ap, bp, pn, lp, bp, sp, lp, bp, ap],
            [np, sp, bp, lp, sp, gq, pp, bp, sp, ap, on, lp, pp, op, sp, lp],
            [gr, ab, bb, ln, n, or, op, sp, n, sp, ln, pp, rp, lp, ap, sp],
            [np, pn, np, rb, rn, sp, lb, sp, np, ar, bn, gr, op, np, bn, sp],
            [bn, rp, ap, gr, op, pr, rn, np, bp, bp, pp, op, op, np, sp, op],
            [n, n, lr, n, a, a, a, pr, a, a, a, n, gq, a, a, n],
            [bp, sr, ap, np, rp, pp, gr, ap, ln, bp, np, np, rp, sb, bp, ap],
            [op, bp, op, lp, ap, ob, gr, nn, an, bp, np, np, op, sp, on, pr],
            [rb, bp, bp, gr, ln, n, rp, sb, bp, an, lb, nr, pp, ap, on, ap],
            [a, ap, a, sp, pp, a, rp, a, a, lp, gq, sp, pp, a, ap, a],
            [bb, rp, np, gr, nn, n, op, ln, ap, pp, lb, pr, op, rp, rn, lp],
            [ob, bn, sb, rb, pb, pb, sb, rb, nb, pr, an, gr, pb, pb, rn, ob],
            [np, ln, lp, gq, ap, bp, sp, ap, ln, an, op, an, ap, bn, ap, ap],
            [gr, ar, rb, sp, np, pb, pp, pp, rp, lp, np, np, lp, np, ap, rp],
            [bn, nn, an, sb, on, nn, ab, pn, rn, or, pn, gr, rn, rn, on, ln],
            [op, bp, op, gq, bp, pp, n, rp, np, n, rp, op, ob, op, sp, rp],
            [bp, pp, rp, sp, rp, gr, rp, nn, nn, nb, op, lp, lp, lp, an, rp],
            [sb, np, pp, sp, lp, sr, gq, pp, op, bp, pp, np, bb, sp, pp, rp],
            [pb, rb, sp, bb, np, sp, np, pp, sp, gq, bp, op, sp, rp, bp, ob],
            [ap, on, bp, sp, ln, gq, ln, sp, op, sn, ap, ap, ap, pp, lp, np],
            [sp, lp, pp, op, np, pr, br, np, rp, op, n, gr, op, lp, bp, op],
            [gq, rp, np, rp, pp, bp, rp, np, br, op, rp, rp, lp, ap, np, sp],
            [rn, sp, np, gq, sp, ab, sp, bp, pp, pp, np, pp, pp, pp, bp, ln],
            [sp, np, sn, bp, rp, op, an, pp, ap, pp, an, op, lr, op, gq, np],
            [lp, ar, op, bp, op, pp, np, bp, pp, op, n, gr, nb, op, op, lp],
            [gq, bp, rp, bp, lp, pp, ap, sp, op, bp, lp, rb, op, np, lb, sb],
            [rn, bp, ap, bp, sn, lb, np, bp, bp, lp, ap, an, np, gq, pp, bp],
            [sp, ap, bp, ap, sp, bp, nn, pp, ab, rp, sb, gr, sn, ap, rp, ar],
            [bp, op, op, lb, op, an, sb, lp, sb, rr, pp, gr, ap, sp, np, bp],
            [np, sp, np, ab, bp, rp, rb, bp, bb, ar, pp, gr, op, rp, bp, np],
            [sp, ob, lp, ap, sp, rp, n, sp, rp, op, lp, ab, ap, op, np, gq],
            [bb, lp, sp, bp, ln, np, sp, gq, gq, op, ar, on, op, rp, np, rp],
            [op, an, sp, rp, sp, np, gq, sp, lr, bp, rn, rp, np, np, ap, op],
            [gq, np, ap, ap, pp, an, lp, pp, rp, sp, lp, pb, ap, np, bb, bb],
            [ap, lp, bn, gq, ap, np, lb, np, op, n, op, rp, rb, ap, rp, np],
            [op, rn, pp, rp, rp, ap, gq, np, sp, ap, rn, rp, bp, np, op, ap],
            [gq, op, bp, pp, pp, pp, nn, lp, bp, rp, ap, ap, rp, bp, bp, np],
            [pp, sb, op, rn, rp, rn, ab, ap, lp, lp, or, gr, bp, bp, rp, pp],
            [ap, pb, rp, ln, lp, an, rb, bp, rn, sp, sr, gr, pp, pp, np, ln],
            [gr, ar, pn, on, bp, ap, sp, rp, ap, rp, lp, op, op, op, pp, op],
            [ar, n, sp, gq, n, n, lp, ap, lp, op, n, n, ap, np, an, lr],
            [op, br, rp, rp, rp, an, np, lp, np, rp, n, gr, pb, bp, np, bp],
            [gq, lb, an, an, pn, nn, np, lp, sp, np, op, sp, bp, sp, np, sp],
            [rp, lp, lp, sb, on, sp, sb, ab, pp, or, ln, gr, lp, lp, sn, pp],
            [a, a, a, a, a, gq, a, a, a, a, a, a, a, a, a, a],
            [ap, np, op, pn, bp, on, np, or, op, pb, rb, gr, sp, pp, rn, rp],
            [gr, lb, ob, an, n, pr, an, sb, n, pb, an, sb, ob, nb, sb, pb],
            [gq, ap, sn, sp, rp, op, rp, sn, pp, sp, pp, rp, rp, ln, pp, on],
            [pp, rr, np, rp, np, lb, sp, ap, pp, bp, n, gr, nb, ap, np, pp],
            [lb, nn, sn, gr, an, n, an, an, nn, pn, ab, pr, on, bn, ln, rn],
            [gq, sp, op, sp, ab, np, bp, op, np, rp, np, bp, pp, ap, rp, bp],
            [gr, rb, ln, ln, pn, bp, sp, op, lp, pp, bp, bp, op, ap, rp, pp],
            [lr, n, lp, sb, sp, np, gq, rp, np, rp, lp, lb, op, rp, np, ap],
            [nb, ap, op, pb, pp, np, np, lp, np, sp, sp, sp, gq, ap, ab, rb],
            [gq, op, op, sp, pp, an, np, lp, op, np, rn, ap, sp, pp, pp, pn],
            [gr, lr, bp, sp, ap, rp, pp, np, sr, rp, sp, sp, op, sp, lp, ap],
            [pp, np, pp, bp, op, np, gq, pp, rp, sp, np, sp, ap, pp, nr, ap],
            [np, lp, op, lp, ap, pn, lp, np, rp, ap, sn, ab, rp, gq, sp, bp],
            [ln, br, np, gq, ap, ap, np, np, bp, bn, lp, pp, sp, pp, op, pn],
            [sp, np, bp, rp, bp, nr, ab, rp, pp, sp, n, pr, op, lp, pp, ap],
            [sp, np, op, np, pp, rp, pp, rp, lp, op, pb, ob, lp, lp, ob, gq],
            [gq, np, pb, lp, sp, ln, sp, ap, sp, ap, sn, sp, bp, pp, sp, sn],
            [an, op, lp, rp, lp, bp, bn, sr, np, ab, nb, gr, ap, pp, lp, bp],
            [ap, bp, rp, bp, pp, gr, bb, rb, bp, bb, rr, bp, pp, lp, on, sp],
            [gr, bb, nb, op, n, br, rp, rp, pb, op, op, np, rp, rp, sp, sp],
            [bp, bp, np, gq, ap, nb, rp, sp, np, lp, pb, bp, np, op, pp, lp],
            [rp, lb, n, gq, op, n, ar, n, pp, rn, op, bn, lp, bp, pp, np],
            [op, np, np, gq, bn, ap, np, lp, rp, bp, sr, n, rp, lp, pp, bn],
            [np, ap, rp, ap, sp, on, sp, pp, lp, rp, ab, lb, np, sp, lb, gq],
            [lb, sp, bp, an, op, pp, gq, ap, ap, ap, rp, op, ab, rp, lp, op],
            [sp, pp, np, ap, an, ap, lp, op, op, gq, bp, pp, pp, lp, bn, pp],
            [sp, rp, rp, pp, rp, on, ap, np, lp, rp, bp, lp, pp, lp, np, gq],
            [sp, lp, sp, sb, on, np, nb, op, sp, pr, nn, gr, lp, lp, ap, lp],
            [rb, bp, pp, gr, bn, n, lp, nn, rp, nn, rb, sr, bp, np, rn, rp],
            [or, sn, rp, gr, np, op, rp, lp, rp, bn, lp, lp, bp, sp, lp, rp],
            [ar, rp, ap, gq, n, n, op, sp, op, ap, n, pp, a, rp, n, nr],
            [ap, br, bp, lb, np, gr, bp, bp, sp, rp, np, pp, pp, ap, nn, rp],
            [sn, ln, pb, gr, op, lp, np, lp, ap, ln, op, nr, lp, np, bp, sp],
            [np, ab, np, nn, rp, an, or, op, bn, n, sr, gr, pb, ap, lp, bp],
            [bp, an, op, gr, np, rb, rp, ab, pp, nn, an, lr, pp, rp, pp, ap],
            [sb, pn, ab, bb, rb, bb, gr, ob, lb, bb, sn, lb, bb, rn, rr, lb],
            [gq, np, sp, ap, ap, bn, op, op, rp, ln, sn, pp, rp, bp, bp, nn],
            [rb, lp, lb, bp, bp, n, pp, bp, lr, np, pp, gr, bp, ap, ap, rp],
            [an, an, nn, an, on, bn, on, rr, sn, bb, lb, gr, ln, sn, an, sn],
            [op, bp, op, bb, bp, ap, pp, ap, ap, gq, pp, pp, rp, op, bp, bp],
            [bp, sp, pp, lp, rp, rn, sb, ap, pp, an, sp, gr, on, pp, ap, op],
            [lp, br, bp, rp, op, np, gq, ap, sp, bp, rp, nb, sp, pb, op, pp],
            [sp, sp, bp, np, rp, bb, bb, pp, op, rb, gq, lb, pp, ap, pb, op],
            [pn, sp, pp, bp, ln, pp, lp, np, rp, rp, bp, nn, np, gq, lp, pp],
            [np, np, nr, sp, bp, ap, n, lp, nr, bp, n, gr, sp, bp, n, op],
            [lp, op, gq, sp, op, ap, nr, np, np, pp, np, op, op, rp, rp, op],
            [rp, on, np, sp, rn, gq, bn, ap, bp, np, np, op, sp, pb, rp, np],
            [sp, sp, op, gq, lp, sp, br, np, rn, sn, bp, rp, op, nn, sp, op],
            [gr, rr, lb, np, bp, lp, op, rp, np, rp, ap, pp, pp, op, rp, op],
            [op, rp, lp, rp, sp, pp, rb, sp, sp, sb, gq, bb, np, np, ob, pp],
            [on, ob, op, gq, np, np, np, ap, pp, pn, np, op, np, sp, sp, bn],
            [ap, sp, np, ob, sp, on, lb, lp, on, ar, rp, gr, sp, sp, pp, pp],
            [bp, op, lp, gr, bp, pb, rb, ob, np, rn, pp, br, bp, op, rp, ap],
            [nb, np, rp, np, op, ap, np, pr, bp, lb, bb, gr, np, lp, op, np],
            [rp, sp, pp, ap, rp, gq, np, bp, sb, pp, lp, np, bp, rb, sp, sp],
            [rp, bn, pp, lp, op, lp, gq, pp, ar, sp, bn, np, bp, pb, pp, ap],
            [sp, rp, sp, on, ar, rp, gq, np, rp, ln, rp, rp, ap, np, lp, pp],
            [sp, ap, gq, pp, op, sn, pp, sp, pp, op, nb, ap, pp, nb, bp, sb],
            [lp, rp, bp, ap, op, gq, sp, rp, ob, an, lp, bp, lp, lb, ap, rp],
            [np, pp, gq, np, on, rp, rp, np, pp, op, np, np, sp, ap, bn, np],
            [gq, ap, np, rp, rn, rp, np, lp, ap, bp, op, sp, ap, pp, ap, bp],
            [np, op, ap, bn, sp, ln, bp, sp, ob, bp, pb, gr, op, lp, np, br],
            [lp, pp, rp, bn, pp, sn, rp, sr, op, ab, bb, gr, pn, np, nn, pp],
            [ap, ar, ap, bp, bp, gr, bp, ln, ap, ap, op, rp, sp, sp, sn, op],
            [nr, n, rp, gq, n, n, a, sp, n, n, n, n, n, n, n, rr],
            [sp, ab, op, lp, np, rn, lp, bp, rr, bp, lp, gr, rp, np, pp, bp],
            [sn, sp, ap, gr, sp, nb, on, bp, sp, ln, rp, lr, bp, np, lp, ap],
            [n, n, n, an, nb, on, lp, or, pp, ab, ab, gr, nn, bp, n, pp],
            [lp, bb, pp, pn, rp, bn, ab, pp, pp, bp, nr, gr, ap, rp, bp, bn],
            [pb, ob, lb, on, rb, pn, rb, or, nb, rb, rb, gr, ab, rb, ln, sb]
        ];

        const newArray = arrays[dayNumber % arrays.length] || [];

        return newArray;
    };

    const bombSpawn = isClassic ? bombSpawnClassic : bombSpawnPuzzle;
    const setBombSpawn = isClassic ? setBombSpawnClassic : setBombSpawnPuzzle;

    const bombCoords = isClassic ? bombCoordsClassic : bombCoordsPuzzle;
    const setBombCoords = isClassic ? setBombCoordsClassic : setBombCoordsPuzzle;

    const boardState = isClassic ? boardStateClassic : boardStatePuzzle;
    const setBoardState = isClassic ? setBoardStateClassic : setBoardStatePuzzle;

    const toggleSound = () => {
        setIsSoundOn(!isSoundOn);
    };

    const getBombSpawnKey = () => {
        return isClassic ? 'bombSpawnClassic' : 'bombSpawnPuzzle';
    }

    const getBombCoordsKey = () => {
        return isClassic ? 'bombCoordsClassic' : 'bombCoordsPuzzle';
    }

    const getSquaresStringKey = () => {
        return isClassic ? 'squaresStringClassic' : 'squaresStringPuzzle';
    }

    const getWaveKey = () => {
        return isClassic ? 'waveClassic' : 'wavePuzzle';
    }

    useEffect(() => {
        const savedWave = storage.getNumber(getWaveKey());
        if (savedWave != null) {
            setWave(savedWave);
        }
    }, []);

    const generateTwoPawns = () => {
        setWave(0);
        storage.set(getWaveKey(), 0);
        if (!pref) {
            setModalVisible(true);
        }
        setBombCoords(null);
        storage.set(getBombCoordsKey(), 25);
        setBombSpawn(false);
        storage.set(getBombSpawnKey(), false);

        const squares = Array(16).fill({ pieceType: null, hasPiece: false, tintColor: null, isPawn: null });
        let pawn1 = Math.floor(Math.random() * 12) + 4;
        let pawn2 = Math.floor(Math.random() * 16);
        
        // Ensure pawns are in different columns
        while (getColumnIndex(pawn1) === getColumnIndex(pawn2)) {
            pawn2 = Math.floor(Math.random() * 16);
        }
        
        function getColumnIndex(squareIndex) {
            return squareIndex % 4;
        }

        squares[pawn1] = { pieceType: 'pawn', hasPiece: true, tintColor: "#756F66", isPawn: true };
        squares[pawn2] = { pieceType: 'pawn', hasPiece: true, tintColor: "#756F66", isPawn: true };

        setBoardState(squares);
        const squaresString = JSON.stringify(squares);
        storage.set(getSquaresStringKey(), squaresString);

        setSelectedPieceIndex(null);
    };

    useEffect(() => {
        const savedSquaresStringClassic = storage.getString('squaresStringClassic');
        if (!savedSquaresStringClassic) {
            generateTwoPawns();
        }
    }, []);

    useEffect(() => {
        const savedClassic = storage.getBoolean('isClassic');
        if (savedClassic != null) {
            setClassic(isClassic);
            // setClassic(savedClassic);
        }
    }, []);

    useEffect(() => {
        const savedBombSpawn = storage.getBoolean(getBombSpawnKey());
        if (savedBombSpawn) {
            setBombSpawn(savedBombSpawn);
        }

        const savedBombCoords = storage.getNumber(getBombCoordsKey());
        if (savedBombCoords) {
            if (savedBombCoords == 25) {
                setBombCoords(null);
            } else {setBombCoords(savedBombCoords)}
        }

        const savedMovesLeftPuzzle = storage.getNumber('movesLeftPuzzle');
        if (savedMovesLeftPuzzle || (savedMovesLeftPuzzle == 0)) {
            setMovesLeftPuzzle(savedMovesLeftPuzzle)
        }

        const savedSquaresString = storage.getString(getSquaresStringKey());
        if (savedSquaresString) {
            const savedSquares = JSON.parse(savedSquaresString);
            setBoardState(savedSquares);
            if (savedSquares.length === 0) {
                setWave(10);
                storage.set(getWaveKey(), 10);
                setModalVisible(true);
            }
        }

        const savedWave = storage.getString(getWaveKey());
            if (savedWave) {
                setWave(savedWave);
            }
        }, 
    [isClassic]);

    useEffect(() => {
        const savedHighScore = storage.getNumber('highScore');
        if (savedHighScore) {
            setHighScore(savedHighScore);
        }
    }, []);

    useEffect(() => {
        console.log("wave " + wave)
    }, [wave]);

    useEffect(() => {
        const savedOutcome = storage.getNumber('outcome');
        if (savedOutcome) {
            setOutcome(savedOutcome);
        }
    }, []);

    //retrieve preference
    useEffect(() => {
        const savedPref = storage.getBoolean('pref');
        if (savedPref) {
            setPref(savedPref);
        }
    }, []);

    useEffect(() => {
        const savedPrefPuzzle = storage.getBoolean('prefPuzzle');
        if (savedPrefPuzzle) {
            setPrefPuzzle(savedPrefPuzzle);
        }
    }, []);


    useEffect(() => {
        const savedScore = storage.getNumber('score');
        if (savedScore) {
            setScore(savedScore);
        }
    }, []);

    const handleMoveClick = () => {
        if (isSoundOn == true) {
            if (boardState[selectedPieceIndex].pieceType == 'astronaut') {
                playWarp();
            } else {
                playMove();
            }
        }
    };
    
    const handleCaptureClick = () => {
        if (isSoundOn == true) {
            if (boardState[selectedPieceIndex].pieceType == 'astronaut') {
                playWarp();
            } else { 
                playCapture();
            }
        }
    };

    const handleBoomClick = () => {
        if (isSoundOn == true) {
            playBoom();
        }
    }

    const generatePuzzle = () => {
        if (!isClassic) {
            setWave(7);
            storage.set(getWaveKey(), 7);
            if (!prefPuzzle) {
                setModalVisible(true);
            }
            setOutcome(0);
            storage.set('outcome', 0)
            setBombCoords(null);
            storage.set(getBombCoordsKey(), 25);
            setBombSpawn(false);
            storage.set(getBombSpawnKey(), false);

            const newSquares = generateNewArray();

            setBoardState(newSquares);
            if (newSquares.length === 0) {
                setWave(10);
                storage.set(getWaveKey(), 10);
                setModalVisible(true);
            }
            const squaresString = JSON.stringify(newSquares);
            storage.set(getSquaresStringKey(), squaresString);

            setSelectedPieceIndex(null);
        }
    };

    const isValidMove = (start, end, board) => {
        if (start !== null) {
            const isOccupiedSquare = board[end].hasPiece;
            const startPiece = board[start];

        if (isOccupiedSquare && board[end].pieceType !== startPiece.pieceType 
            && !(startPiece.isPawn == true && board[end].isPawn == true)) {
            // Invalid move, pieces don't match
            setSelectedPieceIndex(null);
            return false;
        }
        if (startPiece.pieceType === 'pawn') {
            // only move if it is one square up and there is no piece there, or if you can capture
            return (
                (end === start - 4 && !isOccupiedSquare) ||
                (isPawnMove(start, end) && isOccupiedSquare)
            );
        } else if (startPiece.pieceType === 'bomb') {
            // only move if it is one square up and there is no piece there, or if you can capture
            return (
                (end === start - 4 && !isOccupiedSquare) ||
                (isPawnMove(start, end) && isOccupiedSquare)
            );
        } else if (startPiece.pieceType === 'astronaut') {
            return (
                (start != end) && (!isOccupiedSquare || boardState[end].isPawn === true)
            );
        } else if (startPiece.pieceType === 'down') {
            return (
                (end === start + 4 && !isOccupiedSquare) ||
                (isDownMove(start, end) && isOccupiedSquare)
            );
        } else if (startPiece.pieceType === 'left') {
            return isLeftMove(start, end);
        } else if (startPiece.pieceType === 'right') {
            return isRightMove(start, end);
        } else if (startPiece.pieceType === 'knight') {
            return isKnightMove(start, end);
        } else if (startPiece.pieceType === 'bishop') {
            return isBishopMove(start, end, board);
        } else if (startPiece.pieceType === 'rook') {
            return isRookMove(start, end, board);
        } else if (startPiece.pieceType === 'queen') {
            return isQueenMove(start, end, board);
        } else if (startPiece.pieceType === 'king') {
            return isKingMove(start, end);
        }
    }

    return false; // Default case when start is null
    };

    const hasValidMove = (index, board) => {
        if (!board[index].hasPiece) {
            return false;
        }
        
        for (let i = 0; i < board.length; i++) {
            if (isValidMove(index, i, board)) {
            return true;
            }
        }
        return false;
    };

    const handlePieceClick = (index) => {
        if (!isClassic && !(outcome == 0 || outcome == -1 || outcome == null)) {
            return;
        }
        if (selectedPieceIndex !== null) {
        const validMove = isValidMove(selectedPieceIndex, index, boardState);

        const isOccupiedSquare = boardState[index].hasPiece;
        const selectedPiece = boardState[selectedPieceIndex];

        if (validMove) {                    
            if (!isClassic) {
                setMovesLeftPuzzle(movesLeftPuzzle - 1);
                storage.set('movesLeftPuzzle', (movesLeftPuzzle - 1));
                if (movesLeftPuzzle == 1 ) {
                    storage.set('movesLeftPuzzle', 0);
                    setOutcome(2);
                    storage.set('outcome', 2);
                    setShareVisible(true);
                }
            }
                if (boardState[index].hasPiece == true) {
                    handleCaptureClick();
                } else {
                    handleMoveClick();
                }

                if (bombSpawn == true && ((boardState[index].pieceType != 'bomb') && selectedPiece.pieceType != 'bomb')) {
                    handleBoomClick();
                }

                const updatedBoardState = boardState.map((square, i) => {
                    if (i === index) {
                        const colors = ['#756F66', '#76BDFF', '#6CC057', '#F89D49', '#FF7676', '#C46FF9', '#CD7F32', '#A4A4A4', '#EECD23', 'black'];
                        if (selectedPiece.pieceType == 'bomb') {
                            setBombSpawn(false);
                            setBombCoords(null);
                            storage.set(getBombCoordsKey(), 25);
                            storage.set(getBombSpawnKey(), false);

                            if (boardState[index].hasPiece == true) {
                                return { ...square, pieceType: 'knight', hasPiece: true, isPawn: true, tintColor: boardState[index].tintColor  };
                            } else {
                                return { ...square, pieceType: 'pawn', hasPiece: true, isPawn: true, tintColor: selectedPiece.tintColor  };
                            }
                        } else if (bombSpawn && (selectedPiece.pieceType != 'bomb')) {
                            const isBlastRadius = (start, end) => {
                                const grid = [
                                    [0, 1, 2, 3],
                                    [4, 5, 6, 7],
                                    [8, 9, 10, 11],
                                    [12, 13, 14, 15]
                                ];     
                                
                                const startRow = Math.floor(start / 4);
                                const startCol = start % 4;
                                const endRow = Math.floor(end / 4);
                                const endCol = end % 4;
    
                                const rowDiff = startRow - endRow;
                                const colDiff = startCol - endCol;
    
                                return ((rowDiff === 1 && colDiff === 0) || 
                                        (rowDiff === -1 && colDiff === 0) || 
                                        (rowDiff === 0 && colDiff === 1) || 
                                        (rowDiff === 0 && colDiff === -1));
                            }
                            
                            if (isBlastRadius(i, bombCoords) && (boardState[index].pieceType != 'bomb')) {
                                if (boardState[i].tintColor === '#756F66') {
                                    return { ...square, pieceType: null, hasPiece: false, isPawn: null, tintColor: null };
                                } else if (boardState[i].tintColor === '#76BDFF') {
                                    return { ...square, pieceType: 'pawn', hasPiece: true, isPawn: true, tintColor: '#756F66' };
                                } else if (boardState[i].tintColor === '#6CC057') {
                                    return { ...square, pieceType: 'pawn', hasPiece: true, isPawn: true, tintColor: '#76BDFF' };
                                } else if (boardState[i].tintColor === '#F89D49') {
                                    return { ...square, pieceType: 'pawn', hasPiece: true, isPawn: true, tintColor: '#6CC057' };
                                } else if (boardState[i].tintColor === '#FF7676') {
                                    return { ...square, pieceType: 'pawn', hasPiece: true, isPawn: true, tintColor: '#F89D49' };
                                } else if (boardState[i].tintColor === '#C46FF9') {
                                    return { ...square, pieceType: 'pawn', hasPiece: true, isPawn: true, tintColor: '#FF7676' };
                                } else if (boardState[i].tintColor === '#CD7F32') {
                                    return { ...square, pieceType: 'pawn', hasPiece: true, isPawn: true, tintColor: '#C46FF9' };
                                } else if (boardState[i].tintColor === '#A4A4A4') {
                                    return { ...square, pieceType: 'pawn', hasPiece: true, isPawn: true, tintColor: "#CD7F32" };
                                } else if (boardState[i].tintColor === '#EECD23') {
                                    return { ...square, pieceType: 'pawn', hasPiece: true, isPawn: true, tintColor: "#A4A4A4" };
                                } else if (boardState[i].tintColor === 'black') {
                                    return { ...square, pieceType: 'pawn', hasPiece: true, isPawn: true, tintColor: "#EECD23" };                                    
                                } else {
                                    return { ...square, pieceType: null, hasPiece: false, isPawn: null, tintColor: null};
                                }
                                return { ...square, pieceType: null, hasPiece: false, isPawn: null, tintColor: null };
                            }   else if (colors.indexOf(selectedPiece.tintColor) >= colors.indexOf(boardState[index].tintColor)) {
                                if ((selectedPiece.isPawn == true && boardState[index].isPawn == true) && !(selectedPiece.pieceType == 'pawn' && boardState[index].pieceType == 'pawn')) {
                                    if (selectedPiece.pieceType == 'pawn') {
                                        return { ...square, pieceType: 'knight', hasPiece: true, isPawn: false, tintColor: selectedPiece.tintColor };
                                    } else {
                                        return { ...square, pieceType: 'knight', hasPiece: true, isPawn: false, tintColor: boardState[index].tintColor };
                                    }
                                } else if (isOccupiedSquare && selectedPiece.pieceType === 'pawn' && boardState[index].pieceType === 'pawn') {
                                // Square is occupied by another pawn, turn current pawn into a knight
                                return { ...square, pieceType: 'knight', hasPiece: true, isPawn: false, tintColor: selectedPiece.tintColor };
                                } else if (isOccupiedSquare && selectedPiece.pieceType === 'knight' && boardState[index].pieceType === 'knight') {
                                    // Square is occupied by another knight, turn current knight into a bishop
                                    return { ...square, pieceType: 'bishop', hasPiece: true, isPawn: false, tintColor: selectedPiece.tintColor  };
                                } else if (isOccupiedSquare && selectedPiece.pieceType === 'bishop' && boardState[index].pieceType === 'bishop') {
                                    // Square is occupied by another bishop, turn current bishop into a rook
                                    if (wave == 0) {
                                        setWave(1);
                                        waveChange = true;
                                        storage.set(getWaveKey(), 1);
                                        setModalVisible(true);
                                    }
                                    return { ...square, pieceType: 'rook', hasPiece: true, isPawn: false, tintColor: selectedPiece.tintColor  };
                                } else if (isOccupiedSquare && selectedPiece.pieceType === 'rook' && boardState[index].pieceType === 'rook') {
                                    // Square is occupied by another rook, turn current rook into a queen
                                    if (wave == 1) {
                                        setWave(2);
                                        waveChange = true;
                                        storage.set(getWaveKey(), 2);
                                        setModalVisible(true);
                                    }
                                    return { ...square, pieceType: 'queen', hasPiece: true, isPawn: false, tintColor: selectedPiece.tintColor  };
                                } else if (isOccupiedSquare && selectedPiece.pieceType === 'queen' && boardState[index].pieceType === 'queen') {
                                    // Square is occupied by another queen, turn current queen into a king
                                    if (wave == 2) {
                                        setWave(3);
                                        waveChange = true;
                                        storage.set(getWaveKey(), 3);
                                        setModalVisible(true);
                                    }
                                    if ((selectedPiece.tintColor == '#EECD23' || boardState[index].tintColor == '#EECD23')) {
                                        if (!isClassic) {
                                            setOutcome(1);
                                            storage.set('outcome', 1)
                                            setShareVisible(true);
                                        } else if (wave == 4){
                                            setWave(5);
                                            storage.set(getWaveKey(), 5);
                                            setModalVisible(true);
                                        }
                                    }
                                    return { ...square, pieceType: 'king', hasPiece: true, isPawn: false, tintColor: selectedPiece.tintColor  };
                                } else if (isOccupiedSquare && selectedPiece.pieceType === 'king' && boardState[index].pieceType === 'king') {
                                    // Square is occupied by another king, turn current king into a pawn
                                    if (wave == 3) {
                                        setWave(4);
                                        storage.set(getWaveKey(), 4);
                                        setModalVisible(true);
                                    }
                                    if (selectedPiece.tintColor === '#756F66') {
                                        return { ...square, pieceType: 'pawn', hasPiece: true, isPawn: true, tintColor: "#76BDFF" };
                                    } else if (selectedPiece.tintColor === '#76BDFF') {
                                        return { ...square, pieceType: 'pawn', hasPiece: true, isPawn: true, tintColor: "#6CC057" };
                                    } else if (selectedPiece.tintColor === '#6CC057') {
                                        return { ...square, pieceType: 'pawn', hasPiece: true, isPawn: true, tintColor: "#F89D49" };
                                    } else if (selectedPiece.tintColor === '#F89D49') {
                                        return { ...square, pieceType: 'pawn', hasPiece: true, isPawn: true, tintColor: "#FF7676" };
                                    } else if (selectedPiece.tintColor === '#FF7676') {
                                        return { ...square, pieceType: 'pawn', hasPiece: true, isPawn: true, tintColor: "#C46FF9" };
                                    } else if (selectedPiece.tintColor === '#C46FF9') {
                                        return { ...square, pieceType: 'pawn', hasPiece: true, isPawn: true, tintColor: "#CD7F32" };
                                    } else if (selectedPiece.tintColor === '#CD7F32') {
                                        return { ...square, pieceType: 'pawn', hasPiece: true, isPawn: true, tintColor: "#A4A4A4" };
                                    } else if (selectedPiece.tintColor === '#A4A4A4') {
                                        return { ...square, pieceType: 'pawn', hasPiece: true, isPawn: true, tintColor: "#EECD23" };
                                    } else {
                                        return { ...square, pieceType: 'pawn', hasPiece: true, isPawn: true, tintColor: "black" };
                                    }
                                } else {
                                    return { ...square, pieceType: selectedPiece.pieceType, hasPiece: true, isPawn: selectedPiece.pieceType === 'pawn' ||
                                                                                                                    (selectedPiece.pieceType === 'astronaut' ||
                                                                                                                    (selectedPiece.pieceType === 'down' ||
                                                                                                                    (selectedPiece.pieceType === 'left' ||
                                                                                                                    (selectedPiece.pieceType === 'right' ||
                                                                                                                    (selectedPiece.pieceType === 'bomb'))))), tintColor: selectedPiece.tintColor };
                                } 
                            } else {
                                if ((selectedPiece.isPawn == true && boardState[index].isPawn == true) && !(selectedPiece.pieceType == 'pawn' && boardState[index].pieceType == 'pawn')) {
                                    if (selectedPiece.pieceType == 'pawn') {
                                        return { ...square, pieceType: 'knight', hasPiece: true, isPawn: false, tintColor: selectedPiece.tintColor };
                                    } else {
                                        return { ...square, pieceType: 'knight', hasPiece: true, isPawn: false, tintColor: boardState[index].tintColor };
                                    }
                                } else if (isOccupiedSquare && selectedPiece.pieceType === 'pawn' && boardState[index].pieceType === 'pawn') {
                                    // Square is occupied by another pawn, turn current pawn into a knight
                                    return { ...square, pieceType: 'knight', hasPiece: true, isPawn: false, tintColor: boardState[index].tintColor };
                                } else if (isOccupiedSquare && selectedPiece.pieceType === 'knight' && boardState[index].pieceType === 'knight') {
                                    // Square is occupied by another knight, turn current knight into a bishop
                                    return { ...square, pieceType: 'bishop', hasPiece: true, isPawn: false, tintColor: boardState[index].tintColor };
                                } else if (isOccupiedSquare && selectedPiece.pieceType === 'bishop' && boardState[index].pieceType === 'bishop') {
                                    if (wave == 0) {
                                        setWave(1);
                                        waveChange = true;
                                        storage.set(getWaveKey(), 1);
                                        setModalVisible(true);
                                    }
                                    // Square is occupied by another bishop, turn current bishop into a rook
                                    return { ...square, pieceType: 'rook', hasPiece: true, isPawn: false, tintColor: boardState[index].tintColor  };
                                } else if (isOccupiedSquare && selectedPiece.pieceType === 'rook' && boardState[index].pieceType === 'rook') {
                                    if (wave == 1) {
                                        setWave(2);
                                        waveChange = true;
                                        storage.set(getWaveKey(), 2);
                                        setModalVisible(true);
                                    }
                                    // Square is occupied by another rook, turn current rook into a queen
                                    return { ...square, pieceType: 'queen', hasPiece: true, isPawn: false, tintColor: boardState[index].tintColor };
                                } else if (isOccupiedSquare && selectedPiece.pieceType === 'queen' && boardState[index].pieceType === 'queen') {
                                    if (wave == 2) {
                                        setWave(3);
                                        waveChange = true;
                                        storage.set(getWaveKey(), 3);
                                        setModalVisible(true);
                                    }
                                    if ((selectedPiece.tintColor == '#EECD23' || boardState[index].tintColor == '#EECD23')) {
                                        if (!isClassic) {
                                            setOutcome(1);
                                            storage.set('outcome', 1)
                                            setShareVisible(true);
                                        } else if (wave == 4){
                                            setWave(5);
                                            storage.set(getWaveKey(), 5);
                                            setModalVisible(true);
                                        }
                                    }
                                    // Square is occupied by another queen, turn current queen into a king
                                    return { ...square, pieceType: 'king', hasPiece: true, isPawn: false, tintColor: boardState[index].tintColor  };
                                } else if (isOccupiedSquare && selectedPiece.pieceType === 'king' && boardState[index].pieceType === 'king') {
                                    if (wave == 3) {
                                        setWave(4);
                                        storage.set(getWaveKey(), 4);
                                        setModalVisible(true);
                                    }
                                // Square is occupied by another king, turn current king into a pawn
                                    if (boardState[index].tintColor === '#756F66') {
                                        return { ...square, pieceType: 'pawn', hasPiece: true, isPawn: true, tintColor: "#76BDFF" };
                                    } else if (boardState[index].tintColor === '#76BDFF') {
                                        return { ...square, pieceType: 'pawn', hasPiece: true, isPawn: true, tintColor: "#6CC057" };
                                    } else if (boardState[index].tintColor === '#6CC057') {
                                        return { ...square, pieceType: 'pawn', hasPiece: true, isPawn: true, tintColor: "#F89D49" };
                                    } else if (boardState[index].tintColor === '#F89D49') {
                                        return { ...square, pieceType: 'pawn', hasPiece: true, isPawn: true, tintColor: "#FF7676" };
                                    } else if (boardState[index].tintColor === '#FF7676') {
                                        return { ...square, pieceType: 'pawn', hasPiece: true, isPawn: true, tintColor: "#C46FF9" };
                                    } else if (boardState[index].tintColor === '#C46FF9') {
                                        return { ...square, pieceType: 'pawn', hasPiece: true, isPawn: true, tintColor: "#CD7F32" };
                                    } else if (boardState[index].tintColor === '#CD7F32') {
                                        return { ...square, pieceType: 'pawn', hasPiece: true, isPawn: true, tintColor: "#A4A4A4" };
                                    } else if (boardState[index].tintColor === '#A4A4A4') {
                                        return { ...square, pieceType: 'pawn', hasPiece: true, isPawn: true, tintColor: "#EECD23" };
                                    } else {
                                        return { ...square, pieceType: 'pawn', hasPiece: true, isPawn: true, tintColor: "black" };
                                    }
                                } else {
                                    return { ...square, pieceType: selectedPiece.pieceType, hasPiece: true, isPawn: selectedPiece.pieceType === 'pawn' || 
                                                                                                                    (selectedPiece.pieceType === 'astronaut' || 
                                                                                                                    (selectedPiece.pieceType === 'down' ||
                                                                                                                    (selectedPiece.pieceType === 'left' ||
                                                                                                                    (selectedPiece.pieceType === 'right' ||
                                                                                                                    (selectedPiece.pieceType === 'bomb'))))), tintColor: selectedPiece.tintColor };
                                }
                            }
                        }
                        else if (colors.indexOf(selectedPiece.tintColor) >= colors.indexOf(boardState[index].tintColor)) {
                            if ((selectedPiece.isPawn == true && boardState[index].isPawn == true) && !(selectedPiece.pieceType == 'pawn' && boardState[index].pieceType == 'pawn')) {
                                if (selectedPiece.pieceType == 'pawn') {
                                    return { ...square, pieceType: 'knight', hasPiece: true, isPawn: false, tintColor: selectedPiece.tintColor };
                                } else {
                                    return { ...square, pieceType: 'knight', hasPiece: true, isPawn: false, tintColor: boardState[index].tintColor };
                                }
                            } else if (isOccupiedSquare && selectedPiece.pieceType === 'pawn' && boardState[index].pieceType === 'pawn') {
                            // Square is occupied by another pawn, turn current pawn into a knight
                            return { ...square, pieceType: 'knight', hasPiece: true, isPawn: false, tintColor: selectedPiece.tintColor };
                            } else if (isOccupiedSquare && selectedPiece.pieceType === 'knight' && boardState[index].pieceType === 'knight') {
                                // Square is occupied by another knight, turn current knight into a bishop
                                return { ...square, pieceType: 'bishop', hasPiece: true, isPawn: false, tintColor: selectedPiece.tintColor  };
                            } else if (isOccupiedSquare && selectedPiece.pieceType === 'bishop' && boardState[index].pieceType === 'bishop') {
                                if (wave == 0) {
                                    setWave(1);
                                    waveChange = true;
                                    storage.set(getWaveKey(), 1);
                                    setModalVisible(true);
                                }
                                // Square is occupied by another bishop, turn current bishop into a rook
                                return { ...square, pieceType: 'rook', hasPiece: true, isPawn: false, tintColor: selectedPiece.tintColor  };
                            } else if (isOccupiedSquare && selectedPiece.pieceType === 'rook' && boardState[index].pieceType === 'rook') {
                                if (wave == 1) {
                                    setWave(2);
                                    waveChange = true;
                                    storage.set(getWaveKey(), 2);
                                    setModalVisible(true);
                                }
                                // Square is occupied by another rook, turn current rook into a queen
                                return { ...square, pieceType: 'queen', hasPiece: true, isPawn: false, tintColor: selectedPiece.tintColor  };
                            } else if (isOccupiedSquare && selectedPiece.pieceType === 'queen' && boardState[index].pieceType === 'queen') {
                                if (wave == 2) {
                                    setWave(3);
                                    waveChange = true;
                                    storage.set(getWaveKey(), 3);
                                    setModalVisible(true);
                                }
                                if ((selectedPiece.tintColor == '#EECD23' || boardState[index].tintColor == '#EECD23')) {
                                    if (!isClassic) {
                                        setOutcome(1);
                                        storage.set('outcome', 1)
                                        setShareVisible(true);
                                    } else if (wave == 4){
                                        setWave(5);
                                        storage.set(getWaveKey(), 5);
                                        setModalVisible(true);
                                    }
                                }
                                // Square is occupied by another queen, turn current queen into a king
                                return { ...square, pieceType: 'king', hasPiece: true, isPawn: false, tintColor: selectedPiece.tintColor  };
                            } else if (isOccupiedSquare && selectedPiece.pieceType === 'king' && boardState[index].pieceType === 'king') {
                                // Square is occupied by another king, turn current king into a pawn
                                if (wave == 3) {
                                    setWave(4);
                                    storage.set(getWaveKey(), 4);
                                    setModalVisible(true);
                                }
                                if (selectedPiece.tintColor === '#756F66') {
                                    return { ...square, pieceType: 'pawn', hasPiece: true, isPawn: true, tintColor: "#76BDFF" };
                                } else if (selectedPiece.tintColor === '#76BDFF') {
                                    return { ...square, pieceType: 'pawn', hasPiece: true, isPawn: true, tintColor: "#6CC057" };
                                } else if (selectedPiece.tintColor === '#6CC057') {
                                    return { ...square, pieceType: 'pawn', hasPiece: true, isPawn: true, tintColor: "#F89D49" };
                                } else if (selectedPiece.tintColor === '#F89D49') {
                                    return { ...square, pieceType: 'pawn', hasPiece: true, isPawn: true, tintColor: "#FF7676" };
                                } else if (selectedPiece.tintColor === '#FF7676') {
                                    return { ...square, pieceType: 'pawn', hasPiece: true, isPawn: true, tintColor: "#C46FF9" };
                                } else if (selectedPiece.tintColor === '#C46FF9') {
                                    return { ...square, pieceType: 'pawn', hasPiece: true, isPawn: true, tintColor: "#CD7F32" };
                                } else if (selectedPiece.tintColor === '#CD7F32') {
                                    return { ...square, pieceType: 'pawn', hasPiece: true, isPawn: true, tintColor: "#A4A4A4" };
                                } else if (selectedPiece.tintColor === '#A4A4A4') {
                                    return { ...square, pieceType: 'pawn', hasPiece: true, isPawn: true, tintColor: "#EECD23" };
                                } else {
                                    return { ...square, pieceType: 'pawn', hasPiece: true, isPawn: true, tintColor: "black" };
                                }
                            } else {
                                return { ...square, pieceType: selectedPiece.pieceType, hasPiece: true, isPawn: selectedPiece.pieceType === 'pawn' ||
                                                                                                                (selectedPiece.pieceType === 'astronaut' ||
                                                                                                                (selectedPiece.pieceType === 'down' ||
                                                                                                                (selectedPiece.pieceType === 'left' ||
                                                                                                                (selectedPiece.pieceType === 'right' ||
                                                                                                                (selectedPiece.pieceType === 'bomb'))))), tintColor: selectedPiece.tintColor };
                            } 
                        } else {
                            if ((selectedPiece.isPawn == true && boardState[index].isPawn == true) && !(selectedPiece.pieceType == 'pawn' && boardState[index].pieceType == 'pawn')) {
                                if (selectedPiece.pieceType == 'pawn') {
                                    return { ...square, pieceType: 'knight', hasPiece: true, isPawn: false, tintColor: selectedPiece.tintColor };
                                } else {
                                    return { ...square, pieceType: 'knight', hasPiece: true, isPawn: false, tintColor: boardState[index].tintColor };
                                }
                            } else if (isOccupiedSquare && selectedPiece.pieceType === 'pawn' && boardState[index].pieceType === 'pawn') {
                                // Square is occupied by another pawn, turn current pawn into a knight
                                return { ...square, pieceType: 'knight', hasPiece: true, isPawn: false, tintColor: boardState[index].tintColor };
                            } else if (isOccupiedSquare && selectedPiece.pieceType === 'knight' && boardState[index].pieceType === 'knight') {
                                // Square is occupied by another knight, turn current knight into a bishop
                                return { ...square, pieceType: 'bishop', hasPiece: true, isPawn: false, tintColor: boardState[index].tintColor };
                            } else if (isOccupiedSquare && selectedPiece.pieceType === 'bishop' && boardState[index].pieceType === 'bishop') {
                                if (wave == 0) {
                                    setWave(1);
                                    waveChange = true;
                                    storage.set(getWaveKey(), 1);
                                    setModalVisible(true);
                                }
                                // Square is occupied by another bishop, turn current bishop into a rook
                                return { ...square, pieceType: 'rook', hasPiece: true, isPawn: false, tintColor: boardState[index].tintColor  };
                            } else if (isOccupiedSquare && selectedPiece.pieceType === 'rook' && boardState[index].pieceType === 'rook') {
                                if (wave == 1) {
                                    setWave(2);
                                    waveChange = true;
                                    storage.set(getWaveKey(), 2);
                                    setModalVisible(true);
                                }
                                // Square is occupied by another rook, turn current rook into a queen
                                return { ...square, pieceType: 'queen', hasPiece: true, isPawn: false, tintColor: boardState[index].tintColor };
                            } else if (isOccupiedSquare && selectedPiece.pieceType === 'queen' && boardState[index].pieceType === 'queen') {
                                if (wave == 2) {
                                    setWave(3);
                                    waveChange = true;
                                    storage.set(getWaveKey(), 3);
                                    setModalVisible(true);
                                }
                                if ((selectedPiece.tintColor == '#EECD23' || boardState[index].tintColor == '#EECD23')) {
                                    if (!isClassic) {
                                        setOutcome(1);
                                        storage.set('outcome', 1)
                                        setShareVisible(true);
                                    } else if (wave == 4){
                                        setWave(5);
                                        storage.set(getWaveKey(), 5);
                                        setModalVisible(true);
                                    }
                                }
                                // Square is occupied by another queen, turn current queen into a king
                                return { ...square, pieceType: 'king', hasPiece: true, isPawn: false, tintColor: boardState[index].tintColor  };
                            } else if (isOccupiedSquare && selectedPiece.pieceType === 'king' && boardState[index].pieceType === 'king') {
                            // Square is occupied by another king, turn current king into a pawn
                                if (boardState[index].tintColor === '#756F66') {
                                    if (wave == 3) {
                                        setWave(4);
                                        storage.set(getWaveKey(), 4);
                                        setModalVisible(true);
                                    }
                                    return { ...square, pieceType: 'pawn', hasPiece: true, isPawn: true, tintColor: "#76BDFF" };
                                } else if (boardState[index].tintColor === '#76BDFF') {
                                    return { ...square, pieceType: 'pawn', hasPiece: true, isPawn: true, tintColor: "#6CC057" };
                                } else if (boardState[index].tintColor === '#6CC057') {
                                    return { ...square, pieceType: 'pawn', hasPiece: true, isPawn: true, tintColor: "#F89D49" };
                                } else if (boardState[index].tintColor === '#F89D49') {
                                    return { ...square, pieceType: 'pawn', hasPiece: true, isPawn: true, tintColor: "#FF7676" };
                                } else if (boardState[index].tintColor === '#FF7676') {
                                    return { ...square, pieceType: 'pawn', hasPiece: true, isPawn: true, tintColor: "#C46FF9" };
                                } else if (boardState[index].tintColor === '#C46FF9') {
                                    return { ...square, pieceType: 'pawn', hasPiece: true, isPawn: true, tintColor: "#CD7F32" };
                                } else if (boardState[index].tintColor === '#CD7F32') {
                                    return { ...square, pieceType: 'pawn', hasPiece: true, isPawn: true, tintColor: "#A4A4A4" };
                                } else if (boardState[index].tintColor === '#A4A4A4') {
                                    return { ...square, pieceType: 'pawn', hasPiece: true, isPawn: true, tintColor: "#EECD23" };
                                } else {
                                    return { ...square, pieceType: 'pawn', hasPiece: true, isPawn: true, tintColor: "black" };
                                }
                            } else {
                                return { ...square, pieceType: selectedPiece.pieceType, hasPiece: true, isPawn: selectedPiece.pieceType === 'pawn' || 
                                                                                                                (selectedPiece.pieceType === 'astronaut' || 
                                                                                                                (selectedPiece.pieceType === 'down' ||
                                                                                                                (selectedPiece.pieceType === 'left' ||
                                                                                                                (selectedPiece.pieceType === 'right' ||
                                                                                                                (selectedPiece.pieceType === 'bomb'))))), tintColor: selectedPiece.tintColor };
                            }
                        }
                    } else if (i === selectedPieceIndex) {
                        return { ...square, pieceType: null, hasPiece: false, isPawn: null, tintColor: null };
                    } else if (bombSpawn && (selectedPiece.pieceType != 'bomb')) {
                        const isBlastRadius = (start, end) => {
                            const grid = [
                                [0, 1, 2, 3],
                                [4, 5, 6, 7],
                                [8, 9, 10, 11],
                                [12, 13, 14, 15]
                            ];     
                            
                            const startRow = Math.floor(start / 4);
                            const startCol = start % 4;
                            const endRow = Math.floor(end / 4);
                            const endCol = end % 4;

                            const rowDiff = startRow - endRow;
                            const colDiff = startCol - endCol;

                            return ((rowDiff === 1 && colDiff === 0) || 
                                    (rowDiff === -1 && colDiff === 0) || 
                                    (rowDiff === 0 && colDiff === 1) || 
                                    (rowDiff === 0 && colDiff === -1));
                        }
                        
                        if (i === bombCoords) {

                            return { ...square, pieceType: null, hasPiece: false, isPawn: null, tintColor: null };
                        } else if (isBlastRadius(i, bombCoords) && (boardState[index].pieceType != 'bomb')) {
                            if (boardState[i].tintColor === '#756F66') {
                                return { ...square, pieceType: null, hasPiece: false, isPawn: null, tintColor: null };
                            } else if (boardState[i].tintColor === '#76BDFF') {
                                return { ...square, pieceType: 'pawn', hasPiece: true, isPawn: true, tintColor: '#756F66' };
                            } else if (boardState[i].tintColor === '#6CC057') {
                                return { ...square, pieceType: 'pawn', hasPiece: true, isPawn: true, tintColor: '#76BDFF' };
                            } else if (boardState[i].tintColor === '#F89D49') {
                                return { ...square, pieceType: 'pawn', hasPiece: true, isPawn: true, tintColor: '#6CC057' };
                            } else if (boardState[i].tintColor === '#FF7676') {
                                return { ...square, pieceType: 'pawn', hasPiece: true, isPawn: true, tintColor: '#F89D49' };
                            } else if (boardState[i].tintColor === '#C46FF9') {
                                return { ...square, pieceType: 'pawn', hasPiece: true, isPawn: true, tintColor: '#FF7676' };
                            } else if (boardState[i].tintColor === '#CD7F32') {
                                return { ...square, pieceType: 'pawn', hasPiece: true, isPawn: true, tintColor: '#C46FF9' };
                            } else if (boardState[i].tintColor === '#A4A4A4') {
                                return { ...square, pieceType: 'pawn', hasPiece: true, isPawn: true, tintColor: "#CD7F32" };
                            } else if (boardState[i].tintColor === '#EECD23') {
                                return { ...square, pieceType: 'pawn', hasPiece: true, isPawn: true, tintColor: "#A4A4A4" };
                            } else if (boardState[i].tintColor === 'black') {
                                return { ...square, pieceType: 'pawn', hasPiece: true, isPawn: true, tintColor: "#EECD23" };                                    
                            } else {
                                return { ...square, pieceType: null, hasPiece: false, isPawn: null, tintColor: null};
                            }

                            return { ...square, pieceType: null, hasPiece: false, isPawn: null, tintColor: null };
                        }


                        setBombCoords(null);
                        setBombSpawn(false);
                        storage.set(getBombCoordsKey(), 25);
                        storage.set(getBombSpawnKey(), false);

                        return square;
                    } else {

                        return square;
                    }
                }
                );

            if (bombSpawn) {
                setBombSpawn(false);
                storage.set(getBombSpawnKey(), false);
            }
            setBoardState(updatedBoardState);
            setSelectedPieceIndex(null);

            // generate a new pawn in a random empty square
            const emptySquareIndices = updatedBoardState.reduce((indices, square, i) => {
                if (!square.hasPiece) {
                    indices.push(i);
                }
                return indices;
            }, []);
            

            let newPawnIndex;
            do {
                    newPawnIndex = emptySquareIndices[Math.floor(Math.random() * emptySquareIndices.length)];
            } while (updatedBoardState[newPawnIndex].hasPiece);

            // Random chance
            const randomChance = Math.random();
            let randomSpawn;

            console.log(waveChange);

            if (wave == 0 && waveChange) {
                    randomSpawn = 'astronaut';
            } else if (wave == 0) {
                randomSpawn = 'pawn';
            } else if (wave == 1 && waveChange) {
                if (randomChance < 0.34) {
                    randomSpawn = 'down';
                } else if (randomChance < 0.67) {
                    randomSpawn = 'right';
                } else {
                    randomSpawn = 'left';
                }
            } else if (wave == 1) {
                    if (randomChance < 0.05) {
                        randomSpawn = 'astronaut';
                    } else {
                        randomSpawn = 'pawn';
                    }
            } else if (wave == 2 && waveChange) {
                randomSpawn = 'bomb';
                setBombSpawn(true);
                setBombCoords(newPawnIndex);
                storage.set(getBombSpawnKey(), true);
                storage.set(getBombCoordsKey(), newPawnIndex);
            } else if (wave == 2) {
                    if (randomChance < 0.1) {
                        randomSpawn = 'down';
                    } else if (randomChance < 0.2) {
                        randomSpawn = 'right';
                    } else if (randomChance < 0.3) {
                        randomSpawn = 'left';
                    } else if (randomChance < 0.35) {
                        randomSpawn = 'astronaut';
                    } else {
                        randomSpawn = 'pawn';
                    }                    
            } else {
                    if (bombSpawn) {
                        if (randomChance < 0.1) {
                        randomSpawn = 'down';
                        setBombSpawn(false);
                        storage.set(getBombSpawnKey(), false);
                        } else if (randomChance < 0.2) {
                        randomSpawn = 'right';
                        setBombSpawn(false);
                        storage.set(getBombSpawnKey(), false);
                        } else if (randomChance < 0.3) {
                        randomSpawn = 'left';
                        setBombSpawn(false);
                        storage.set(getBombSpawnKey(), false);
                        } else if (randomChance < 0.35) {
                        randomSpawn = 'astronaut';
                        setBombSpawn(false);
                        storage.set(getBombSpawnKey(), false);
                        } else {
                        randomSpawn = 'pawn';
                        setBombSpawn(false);
                        storage.set(getBombSpawnKey(), false);
                        }
                    } else {
                        if (randomChance < 0.1) {
                        randomSpawn = 'down';
                        setBombSpawn(false);
                        storage.set(getBombSpawnKey(), false);
                        } else if (randomChance < 0.2) {
                        randomSpawn = 'right';
                        setBombSpawn(false);
                        storage.set(getBombSpawnKey(), false);
                        } else if (randomChance < 0.3) {
                        randomSpawn = 'left';
                        setBombSpawn(false);
                        storage.set(getBombSpawnKey(), false);
                        } else if (randomChance < 0.35) {
                        randomSpawn = 'astronaut';
                        setBombSpawn(false);
                        storage.set(getBombSpawnKey(), false);
                        } else if (randomChance < 0.45) {
                        randomSpawn = 'bomb';
                        setBombSpawn(true);
                        setBombCoords(newPawnIndex);
                        storage.set(getBombSpawnKey(), true);
                        storage.set(getBombCoordsKey(), newPawnIndex);
                        } else {
                        randomSpawn = 'pawn';
                        setBombSpawn(false);
                        storage.set(getBombSpawnKey(), false);
                        }
                    }
            }

            const newBoardState = updatedBoardState.map((square, i) => {
                    if (i === newPawnIndex) {
                        console.log(newPawnIndex + ". new " + randomSpawn + " has spawned, the wave is " + wave + ", and waveChange is " + waveChange)
                        return { ...square, pieceType: randomSpawn, hasPiece: true, isPawn: true, tintColor: '#756F66'};
                    } else {
                        return square;
                    }
            });

            waveChange = false;

            console.log("reverted to " + waveChange)

            setBoardState(newBoardState);

            const isGameOver = () => {
                for (let i = 0; i < newBoardState.length; i++) {
                    if (hasValidMove(i, newBoardState)) {
                        return false;
                    }
                }
                if (!isClassic) {
                    setOutcome(3);
                    storage.set('outcome', 3)
                    setShareVisible(true);
                } else {
                    setScore(keepScore() + 10);
                    setWave(6);
                    storage.set(getWaveKey(), 6);
                    setModalVisible(true);
                    return true;
                }
            };
            isGameOver();

            const squaresString = JSON.stringify(newBoardState);
            storage.set(getSquaresStringKey(), squaresString);
            storage.set('score', score);
        }

        // Reset the selected piece index when trying to move to an occupied square
        setSelectedPieceIndex(null);
        } else {
            // select clicked piece
            if (boardState[index].hasPiece) {
                setSelectedPieceIndex(index);
            }
        }
    };

    const isPawnMove = (start, end) => {
        const grid = [
            [0, 1, 2, 3],
            [4, 5, 6, 7],
            [8, 9, 10, 11],
            [12, 13, 14, 15]
        ];
        
        const startRow = Math.floor(start / 4);
        const startCol = start % 4;
        const endRow = Math.floor(end / 4);
        const endCol = end % 4;
        
        const rowDiff = startRow - endRow;
        const colDiff = startCol - endCol;
        return (rowDiff === 1 && colDiff === 1) || (rowDiff === 1 && colDiff === -1);
    };

    const isDownMove = (start, end) => {
        const grid = [
            [0, 1, 2, 3],
            [4, 5, 6, 7],
            [8, 9, 10, 11],
            [12, 13, 14, 15]
        ];
        
        const startRow = Math.floor(start / 4);
        const startCol = start % 4;
        const endRow = Math.floor(end / 4);
        const endCol = end % 4;
        
        const rowDiff = startRow - endRow;
        const colDiff = startCol - endCol;

        if (rowDiff == 0 && colDiff == 0) {
            return false;
        }

        return (rowDiff === -1 && colDiff === 1) || (rowDiff === -1 && colDiff === -1);
    };

    const isLeftMove = (start, end) => {
        const grid = [
            [0, 1, 2, 3],
            [4, 5, 6, 7],
            [8, 9, 10, 11],
            [12, 13, 14, 15]
        ];
        
        const startRow = Math.floor(start / 4);
        const startCol = start % 4;
        const endRow = Math.floor(end / 4);
        const endCol = end % 4;
        
        const rowDiff = startRow - endRow;
        const colDiff = startCol - endCol;

        // See if it's moved at all
        if (rowDiff == 0 && colDiff == 0) {
            return false;
        }

        if (boardState[end].hasPiece == false) {
            return (rowDiff == 0 && colDiff == 1)            
        } else {
            return (rowDiff === -1 && colDiff === 1) || (rowDiff === 1 && colDiff === 1);
        }
    };

    const isRightMove = (start, end) => {
        const grid = [
            [0, 1, 2, 3],
            [4, 5, 6, 7],
            [8, 9, 10, 11],
            [12, 13, 14, 15]
        ];
        
        const startRow = Math.floor(start / 4);
        const startCol = start % 4;
        const endRow = Math.floor(end / 4);
        const endCol = end % 4;
        
        const rowDiff = startRow - endRow;
        const colDiff = startCol - endCol;

        // See if it's moved at all
        if (rowDiff == 0 && colDiff == 0) {
            return false;
        }

        if (boardState[end].hasPiece == false) {  
            return (rowDiff == 0 && colDiff == -1)
        
        } else {   
            return (rowDiff === -1 && colDiff === -1) || (rowDiff === 1 && colDiff === -1);
        }
    };

    const isKnightMove = (start, end) => {
        const grid = [
            [0, 1, 2, 3],
            [4, 5, 6, 7],
            [8, 9, 10, 11],
            [12, 13, 14, 15]
        ];
        
        const startRow = Math.floor(start / 4);
        const startCol = start % 4;
        const endRow = Math.floor(end / 4);
        const endCol = end % 4;
        
        const rowDiff = Math.abs(startRow - endRow);
        const colDiff = Math.abs(startCol - endCol);
        return (rowDiff === 2 && colDiff === 1) || (rowDiff === 1 && colDiff === 2);
    };

    const isBishopMove = (start, end, board) => {
        const grid = [
            [0, 1, 2, 3],
            [4, 5, 6, 7],
            [8, 9, 10, 11],
            [12, 13, 14, 15]
        ];

        const startRow = Math.floor(start / 4);
        const startCol = start % 4;
        const endRow = Math.floor(end / 4);
        const endCol = end % 4;

        const rowDiff = Math.abs(startRow - endRow);
        const colDiff = Math.abs(startCol - endCol);

        // See if it's moved at all
        if (rowDiff == 0) {
            return false;
        }

        // Check if it's a diagonal move
        if (rowDiff !== colDiff) {
            return false;
        }

        // Determine the direction of movement
        const rowDirection = startRow < endRow ? 1 : -1;
        const colDirection = startCol < endCol ? 1 : -1;

        // Check the squares between start and end positions
        for (let i = 1; i < rowDiff; i++) {
            const currentRow = startRow + i * rowDirection;
            const currentCol = startCol + i * colDirection;
            const currentIndex = grid[currentRow][currentCol];

            // If any square is occupied, the move is invalid
            if (board[currentIndex].hasPiece) {
                return false;
            }
        }

        return true;
    };     

    const isRookMove = (start, end, board) => {
        const grid = [
            [0, 1, 2, 3],
            [4, 5, 6, 7],
            [8, 9, 10, 11],
            [12, 13, 14, 15]
        ];
        
        const startRow = Math.floor(start / 4);
        const startCol = start % 4;
        const endRow = Math.floor(end / 4);
        const endCol = end % 4;
        
        const rowDiff = Math.abs(startRow - endRow);
        const colDiff = Math.abs(startCol - endCol);

        // See if it's moved at all
        if (rowDiff == 0 && colDiff == 0) {
            return false;
        }
        
        // Check if it's a valid horizontal or vertical move (same row or same column)
        if (startRow === endRow || startCol === endCol) {
            // Check if there are any pieces in the horizontal or vertical path
            if (startRow === endRow) {
                // Horizontal move
                const colIncrement = startCol < endCol ? 1 : -1;
                let currentCol = startCol + colIncrement;
                
                while (currentCol !== endCol) {
                    const currentIndex = grid[startRow][currentCol];
                    if (board[currentIndex].hasPiece) {
                    // There's a piece blocking the path
                        return false;
                    }
                    currentCol += colIncrement;
                }
            } else {
                // Vertical move
                const rowIncrement = startRow < endRow ? 1 : -1;
                let currentRow = startRow + rowIncrement;
                
                while (currentRow !== endRow) {
                    const currentIndex = grid[currentRow][startCol];
                    if (board[currentIndex].hasPiece) {
                        // There's a piece blocking the path
                        return false;
                    }
                    currentRow += rowIncrement;
                }
            }
            return true;
        }
        return false;
    };

    const isQueenMove = (start, end, board) => {
        const grid = [
            [0, 1, 2, 3],
            [4, 5, 6, 7],
            [8, 9, 10, 11],
            [12, 13, 14, 15]
        ];

        const startRow = Math.floor(start / 4);
        const startCol = start % 4;
        const endRow = Math.floor(end / 4);
        const endCol = end % 4;
        
        const rowDiff = Math.abs(startRow - endRow);
        const colDiff = Math.abs(startCol - endCol);

        // See if it's moved at all
        if (rowDiff == 0 && colDiff == 0) {
            return false;
        }
        
        // Check if it's a valid horizontal, vertical, or diagonal move
        if (startRow === endRow || startCol === endCol || rowDiff === colDiff) {
            // Check if there are any pieces in the path
            const rowIncrement = startRow < endRow ? 1 : startRow > endRow ? -1 : 0;
            const colIncrement = startCol < endCol ? 1 : startCol > endCol ? -1 : 0;
            let currentRow = startRow + rowIncrement;
            let currentCol = startCol + colIncrement;
            
            while (currentRow !== endRow || currentCol !== endCol) {
                const currentIndex = grid[currentRow][currentCol];
                if (board[currentIndex].hasPiece) {
                    // There's a piece blocking the path
                    return false;
                }
                
                currentRow += rowIncrement;
            currentCol += colIncrement;
            }
            return true;
        } 
        return false;
    };
    
    const isKingMove = (start, end) => {
        const grid = [
            [0, 1, 2, 3],
            [4, 5, 6, 7],
            [8, 9, 10, 11],
            [12, 13, 14, 15]
        ];

        const startRow = Math.floor(start / 4);
        const startCol = start % 4;
        const endRow = Math.floor(end / 4);
        const endCol = end % 4;
        
        const rowDiff = Math.abs(startRow - endRow);
        const colDiff = Math.abs(startCol - endCol);

        // See if it's moved at all
        if (rowDiff == 0 && colDiff == 0) {
            return false;
        }
        
        // Check if it's a valid move
        return rowDiff <= 1 && colDiff <= 1;
    };

    const renderSquares = () => {
        return boardState.map((squares, index) => (
            <Square 
                key={index} 
                id={index} 
                pieceType={squares.pieceType} 
                hasPiece={squares.hasPiece}
                tintColor={squares.tintColor}
                onPress={() => handlePieceClick(index)}
                selected={selectedPieceIndex === index}
            />
        ));
    };
    const keepScore = () => {
        if (isClassic) {
            let boardStateScore;
            const savedSquaresStringClassic = storage.getString('squaresStringClassic');
            if (savedSquaresStringClassic) {
                boardStateScore = JSON.parse(savedSquaresStringClassic);
            let newScore = 0;
            for (let i = 0; i < boardStateScore.length; i++) {
                if (boardStateScore[i].tintColor === '#756F66') {
                    newScore += 0;
                } else if (boardStateScore[i].tintColor === '#76BDFF') {
                    newScore += 126;
                } else if (boardStateScore[i].tintColor === '#6CC057') {
                    newScore += 252;
                } else if (boardStateScore[i].tintColor === '#F89D49') {
                    newScore += 378;
                } else if (boardStateScore[i].tintColor === '#FF7676') {
                    newScore += 504;
                } else if (boardStateScore[i].tintColor === '#C46FF9') {
                    newScore += 630;
                } else if (boardStateScore[i].tintColor === '#CD7F32') {
                    newScore += 756;
                } else if (boardStateScore[i].tintColor === '#A4A4A4') {
                    newScore += 882;
                } else if (boardStateScore[i].tintColor === '#EECD23') {
                    newScore += 1008;
                } else if (boardStateScore[i].tintColor === 'black') {
                    newScore += 1134;
                } else { newScore += 0;};
    
                if (boardStateScore[i].isPawn === true) {
                    newScore += 1;
                } else if (boardStateScore[i].pieceType === 'knight') {
                    newScore += 3;
                } else if (boardStateScore[i].pieceType === 'bishop') {
                    newScore += 7;
                } else if (boardStateScore[i].pieceType === 'rook') {
                    newScore += 15;
                } else if (boardStateScore[i].pieceType === 'queen') {
                    newScore += 31;
                } else if (boardStateScore[i].pieceType === 'king') {
                    newScore += 63;
                } else { newScore += 0; };
            }
    
            if (newScore > storage.getNumber('highScore')) {
                setHighScore(newScore);
                storage.set('highScore', newScore);
            } else if (storage.getNumber('highScore') === undefined) {
                storage.set('highScore', newScore);
            }
    
            return(newScore);
            }
        }
    };

    const keepMovesLeft = () => {
        let newMovesLeft = 0;
        newMovesLeft = movesLeftPuzzle;
        return (newMovesLeft);
    }

    const handleBack = () => {
        setClassic(!isClassic);
        setBombSpawn(bombSpawnClassic);
        setBombCoords(bombCoordsClassic);
        storage.set('isClassic', !isClassic);
    }

    const handlePuzzle = () => {
        setClassic(!isClassic);
        setBombSpawn(bombSpawnPuzzle);
        setBombCoords(bombCoordsPuzzle);
        storage.set('isClassic', !isClassic);
    }

    const outcomeColorStyleButton = outcome === 0 || outcome == -1 || outcome === null ? styles.puzzleButtonOn : styles.puzzleButtonOff;
    
    const outcomeColorStyleButtonText = outcome === 0 || outcome === -1 || outcome === null ? styles.puzzleButtonTextOn : styles.puzzleButtonTextOn;
    
    const outcomeColorStyleDate = outcome === 0 || outcome === -1 ? styles.puzzleDateOn : styles.puzzleDateOn;
    
    const outcomeColorStyleDateText = outcome === 0 || outcome === -1 ? styles.puzzleDateTextOn : styles.puzzleDateTextOn; 

    const outcomePuzzleDateString = outcome === 0 || outcome === -1 || outcome === null ? `${formattedDate} • Puzzle ${dayNumber + 1}` : `Share Today's Puzzle`;

    return (
        <View style={styles.container}>
            <View style={styles.header}> 
                <View style={styles.headerTop}>
                    <View style={styles.headerTippity}>
                        <Text style={styles.title}>Take!</Text>
                        <Image source={require('../assets/pieces.png')} style={styles.image}></Image>
                    </View>
                    <Text style={styles.desc}>Capture matching pieces to reach the golden king.</Text>
                </View>
                {isClassic && <View style={styles.headerBottom}>
                    <TouchableOpacity style={styles.button} onPress={generateTwoPawns}>
                        <Text style={styles.buttonText}>New Game</Text>
                    </TouchableOpacity>
                <View style={styles.headerScores}>
                    <View style={styles.score}> 
                        <Text style={styles.scoreTitle}>SCORE</Text>
                        <Text style={styles.scoreNumber}>{keepScore() || 0}</Text>
                    </View>
                    <View style={styles.score}>
                        <Text style={styles.scoreTitle}>BEST</Text>
                        <Text style={styles.scoreNumber}>{highScore}</Text>
                    </View>
                </View>
            </View>}
                {!isClassic && <View style={styles.headerBottom}>
                        <TouchableOpacity style={styles.backButton} onPress={handleBack}>
                            <Image style={{ width: 20, height: 20, resizeMode: 'contain' }} 
                            source={require('../assets/chevronleft.png')}/>
                            <Text style={styles.backButtonText}>Back to Classic</Text>
                        </TouchableOpacity>
                    <View style={styles.headerScores}>
                        <View style={styles.score}>
                            <Text style={styles.scoreTitle}>MOVES LEFT</Text>
                            <Text style={styles.scoreNumber}>{keepMovesLeft()}</Text>
                        </View>
                    </View>
                </View>}
            {isClassic && <TouchableOpacity style={[outcomeColorStyleButton]} onPress={handlePuzzle}>
                <Text style={[outcomeColorStyleButtonText]}>Play Today's Puzzle!</Text>
            </TouchableOpacity> }
            {!isClassic && <TouchableOpacity 
                style={[outcomeColorStyleDate]} 
                activeOpacity={outcome === -1 ? 0.8 : 1} 
                onPress={outcome === -1 ? generatePuzzle() : (outcome === 0 || outcome === null ? undefined : () => setShareVisible(true))}>
                <Text style={[outcomeColorStyleDateText]} >{outcomePuzzleDateString}</Text>
            </TouchableOpacity>}
            </View>
            <View>
                <View style={styles.board2}>
                <View style={styles.board}>{renderSquares()}</View>
                </View>
                <View style={{ flexDirection: "row", marginTop: 8, alignItems: "center", justifyContent: "space-between", paddingHorizontal: 6 }}>
                    <TouchableOpacity
                        onPress={toggleSound}>
                        <Image style={{ width: 24, height: 24, resizeMode: 'contain' }} 
                        source={isSoundOn ? require('../assets/sound.png') : require('../assets/mute.png')}/>
                    </TouchableOpacity>
                    <View style={{ flexDirection: "row", alignItems: "baseline" }}>
                        <Text style={styles.credit}>Created by </Text>
                        <Text
                        style={styles.hyperlink}
                        onPress={() => {
                            Linking.openURL('https://joshuawolk.com');
                        }}>
                        Joshua Wolk.
                        </Text>
                    </View>
                    <TouchableOpacity
                        onPress={() => setTutorialVisible(true)}>
                        <Image style={{ width: 24, height: 24, resizeMode: 'contain' }} source={require('../assets/questionmark.png')} />
                    </TouchableOpacity>
                </View>
                <TouchableOpacity 
                style={styles.application}
                onPress={() => {
                            Linking.openURL('https://apps.apple.com/us/app/take-a-chess-puzzle-game/id6450381036');
                        }}
                >
                    <Text style={styles.applicationText}>get the app</Text>
                </TouchableOpacity>
            </View>

            <Popup wave={wave} modalVisible={modalVisible} setModalVisible={setModalVisible} setPref={setPref} setPrefPuzzle={setPrefPuzzle} score={score} />
            <Tutorial tutorialVisible={tutorialVisible} setTutorialVisible={setTutorialVisible}/>
            <ShareComponent shareVisible={shareVisible} setShareVisible={setShareVisible} movesLeftPuzzle={movesLeftPuzzle} outcome={outcome}/>
        </View>
        );
}

const styles = StyleSheet.create({
    puzzleButtonOn: {
        backgroundColor: '#79A86B',
        borderRadius: 8,
        borderWidth: 1,
        borderColor: `#79A86B`,
        paddingVertical: 9,
        paddingHorizontal: 30,
    },
    puzzleButtonOff: {
        backgroundColor: '#ECECEC',
        borderRadius: 8,
        borderWidth: 1,
        borderColor: `#ECECEC`,
        paddingVertical: 9,
        paddingHorizontal: 30,
    },
    puzzleButtonTextOn: {
        color: 'white',
        alignSelf: 'center',
        fontWeight: 'bold',
        fontSize: 12,
    },
    puzzleButtonTextOff: {
        color: '#FFFFFF',
        alignSelf: 'center',
        fontWeight: 'bold',
        fontSize: 12,
    },
    puzzleDateOn: {
        backgroundColor: 'white',
        borderRadius: 8,
        borderWidth: 1,
        borderColor: `#79A86B`,
        paddingVertical: 9,
        paddingHorizontal: 30,
    },
    puzzleDateTextOn: {
        color: '#79A86B',
        alignSelf: 'center',
        fontWeight: 'bold',
        fontSize: 12,
    },
    puzzleDateOff: {
        backgroundColor: 'white',
        borderRadius: 8,
        borderWidth: 1,
        borderColor: `#D8D8D8`,
        paddingVertical: 9,
        paddingHorizontal: 30,
    },
    puzzleDateTextOff: {
        color: '#D8D8D8',
        alignSelf: 'center',
        fontWeight: 'bold',
        fontSize: 12,
    },
    application: {
        alignSelf: 'center',
        borderColor: '#B9ADA1',
        padding: 8,
        borderWidth: 1,
        borderRadius: 8,
        marginTop: 12,
    },
    applicationText: {
        color: '#B9ADA1',
        lineHeight: 10,
        fontSize: 14,
    },
    container: {
        flex: 1,
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'center',
    },
    header: {
    flexDirection: 'column',
    gap: 16,
    },
    headerTop: {
    flexDirection: 'column',
    },
    title: {
        fontSize: 70,
        fontWeight: 'bold',
        color: '#756F66',
        overflow: 'visible',
    },
    headerTippity: {
        flexDirection: 'row',
        gap: 26,
        alignItems: 'baseline',
    },
    image: {
        width: 119.08, 
        height: 83.97,
    },
    desc: {
        fontSize: 14,
        color: '#756F66',
    },
    credit: {
        fontSize: 14,
        color: '#B9ADA1',
        fontWeight: 'bold',
    },
    hyperlink: {
        fontSize: 14,
        color: '#756F66',
        textDecorationLine: 'underline',
        fontWeight: 'bold',
        marginLeft: 0,
    },
    headerBottom: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    headerScores: {
        flexDirection: 'row',
        gap: 20,
    },
    button: {
        backgroundColor: '#EBE4DB',
        width: 104,
        height: 60,
        borderRadius: 18,
        justifyContent: 'center',
        alignItems: 'center',
        // flex: 1,
    },
    backButton: {
        backgroundColor: '#EBE4DB',
        height: 60,
        borderRadius: 18,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        gap: 4,
        alignSelf: 'flex-start',
        paddingHorizontal: 20,
        paddingVertical: 4,
    },
    buttonText: {
        fontSize: 12,
        fontWeight: 'bold',
        color: '#756F66',
        flex: 0,
    },
    backButtonText: {
        fontSize: 12,
        fontWeight: 'bold',
        color: '#756F66',
    },
    score: {
        backgroundColor: '#B9ADA1',
        borderRadius: 18,
        justifyContent: 'center',
        alignItems: 'center',
        // borderWidth: 1,
        // borderColor: 'black',
        height: 60,
        paddingHorizontal: 9,
    },
    scoreTitle: {
        color: "#EBE4DB",
        fontSize: 10,
        fontWeight: "bold",
        // borderWidth: 1,
        // borderColor: 'black',
    },
    scoreNumber: {
        color: "#FFFFFF",
        fontSize: 26,
        fontWeight: "bold",
        // borderWidth: 1,
        // borderColor: 'black',
    },
    board: {
        // borderWidth: 1,
        // borderColor: 'black',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignContent: 'flex-start',
        width: 320,
        height: 320,
        backgroundColor: '#B9ADA1',
        borderRadius: 10,
        overflow: 'hidden',
    },
    board2: {
        // borderWidth: 1,
        // borderColor: 'black',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignContent: 'flex-start',
        width: 350,
        height: 350,
        backgroundColor: '#B9ADA1',
        paddingTop: 15,
        paddingLeft: 15,
        borderRadius: 20,
        marginTop: 18,
    },
});


export default Gameboard