import React, { useState, useEffect } from 'react';
import { StyleSheet, Text, View, TouchableOpacity, Image, Modal, Dimensions } from 'react-native';
import { MMKV } from 'react-native-mmkv'
export const storage = new MMKV();
import * as Clipboard from 'expo-clipboard';

const currentDate = new Date();
const formattedDate = currentDate.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });

const ShareComponent = ({shareVisible, setShareVisible, movesLeftPuzzle, outcome}) => {
    let movesMade = 25 - movesLeftPuzzle;
    let date = formattedDate;

    const [copiedText, setCopiedText] = React.useState('');
    const [copiedConfirmationVisible, setCopiedConfirmationVisible] = useState(false);

    const copyToClipboard = async () => {
        switch (outcome) {
            case 1:
                await Clipboard.setStringAsync(`♟ Take! ${date} ♟
                
👑 Golden king in ${movesMade} moves!
https://takechess.com`);
                break;
            case 2:
                await Clipboard.setStringAsync(`♟ Take! ${date} ♟
                
❌ Ran out of moves.
https://takechess.com`);
                break;
            case 3:
                await Clipboard.setStringAsync(`♟ Take! ${date} ♟
                    
❌ Stalemate in ${movesMade} moves.
https://takechess.com`);
                break;
        }

            // Show copied confirmation
            setCopiedConfirmationVisible(true);
        };

        const fetchCopiedText = async () => {
        const text = await Clipboard.getStringAsync();
        setCopiedText(text);
        };

        useEffect(() => {
        fetchCopiedText();
        }, []);

        useEffect(() => {
            // Hide the copied confirmation after 3 seconds
            if (copiedConfirmationVisible) {
              const timeoutId = setTimeout(() => {
                setCopiedConfirmationVisible(false);
              }, 3000);
        
              return () => clearTimeout(timeoutId);
            }
        }, [copiedConfirmationVisible]);

    const getPieceDimensions = () => {
        switch (outcome) {
            case 1:
                return { width: 54, height: 91 };
            case 2:
                    return { width: 91, height: 86 };
            case 3:
                    return { width: 91, height: 86 };
        };
    };

    const renderPiece = () => {
        let pieceImageSource;
        const pieceDimensions = getPieceDimensions();
        switch (outcome) {
            case 1:
            pieceImageSource = require('../assets/goldenking.png');
            break;
            case 2:
            pieceImageSource = require('../assets/surrender.png');
            break;
            case 3:
                pieceImageSource = require('../assets/surrender.png');
                break;
            default:
            pieceImageSource = null;
        }
        return (
            <Image
            source={pieceImageSource}
            style={[styles.pieceImage, pieceDimensions]}
            />
        )
    }
    const getHeader = () => {
        switch (outcome) {
            case 1:
                return `Golden king in ${movesMade} moves!`;
            case 2:
                return "You've run out of moves.";
            case 3:
                return "Stalemate!";
        };
    }
    const getDesc = () => {
    switch (outcome) {
        case 1:
        return `You had ${movesLeftPuzzle} moves remaining.

Share your score with friends to see who takes the crown!`;
        case 2:
        return `That's okay. Next time, you'll do grand!.

Share your score with friends to see who takes the crown!`;
        case 3:
    return `That's okay. Next time, you'll do grand!.

Share your score with friends to see who takes the crown!`;
    }
    };

    const windowWidth = Dimensions.get('window').width;



return (
    <Modal 
        transparent={true}
        visible={shareVisible}
        animationType="fade"
    >
    <TouchableOpacity 
        style={styles.container}
        activeOpacity={1}
        onPress={() => {
            console.log('Modal has been clicked.');
            setShareVisible(!shareVisible);
        }}
    >
    <View style={styles.popupBox}>
            {renderPiece()}
            <View style={styles.textBox}>
                <Image style={styles.piece}></Image>
                <Text style={styles.header}>{getHeader()}</Text>
                <Text style={styles.desc}>{getDesc()}</Text>
                <TouchableOpacity style={styles.shareButton} onPress={copyToClipboard}>
                    <Text style={styles.shareButtonText}>Share</Text>
                    <Image style={{ width: 20, height: 20, resizeMode: 'contain' }} source={require('../assets/shareicon.png')} />
                </TouchableOpacity>
            </View>
        </View>     
    </TouchableOpacity>
    {copiedConfirmationVisible && (
        <View style={styles.copiedConfirmation}>
            <Text style={styles.copiedText}>Copied results to clipboard</Text>
        </View>
    )}   
    </Modal>
);
};

const styles = StyleSheet.create({
    copiedConfirmation: {
        position: 'absolute',
        bottom: 40,
        left: '46%',
        backgroundColor: '#333',
        padding: 10,
        borderRadius: 8,
        transform: [{ translateX: -50 }],
      },
      copiedText: {
        color: 'white',
        fontSize: 16,
      },
    shareButton: {
        backgroundColor: "#79A86B",
        borderRadius: 20,
        gap: 10,
        paddingHorizontal: 30,
        paddingVertical: 10,
        flexDirection: "row",
        alignSelf: 'flex-start',
        marginTop: 20,
    },
    shareButtonText: {
        color: "white",
        fontWeight: "bold",
        fontSize: 16,
    },
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    popupBox: {
        display: 'flex',
        // flex: 0,
        flexDirection: 'column',
        backgroundColor: '#EBE4DB',
        borderColor: '#B9ADA1',
        borderWidth: 2,
        alignItems: 'center',
        justifyContent: 'center',
        paddingHorizontal: 30,
        paddingTop: 20,
        paddingBottom: 20,
        borderRadius: 40,
        gap: 16,
        shadowOpacity: 0.25,

        shadowColor: '#3A3733',
        shadowOffset: {
            height: 4,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
    },
    textBox : {
        flexDirection: 'column',
        gap: 4,
    },
    header : {
        fontSize: 20,
        fontWeight: 'bold',
        color: '#756F66',
        textAlign: 'left',
    },
    desc : {
        fontSize: 16,
        fontWeight: '300',
        color: '#756F66',
        width: 292,
        // textAlign: 'left',
    },
    pieceImage: {
        // borderWidth: 1,
        // borderColor: 'black',
        resizeMode: 'contain',
    },
    percentagesImage: {
        // borderWidth: 1,
        // borderColor: 'black',
        resizeMode: 'contain',
    },
    checkbox: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    checkboxText: {
        fontSize: 16,
        fontWeight: '300',
        color: '#756F66',
    }
});

export default ShareComponent;